const updateReminderSchedule = `mutation UpdateReminderSchedule($input: UpdateReminderScheduleInput!) {
  updateReminderSchedule(input: $input) {
    id
    owner
    medicationDetails {
      lastTaken
      dosageAmount
      dosageUnit
      time
      route
      name
    }
    vitalDetails {
      time
      vitalType
      day
    }
  }
}
`;
export default updateReminderSchedule;
