import React, { useState, useEffect } from 'react';
import {
  Typography, Input, Radio, InputNumber, Checkbox, Col, Divider,
} from 'antd';
import _ from 'lodash';
import { navigate } from '@reach/router';
import { useLocalStorage } from 'standard-hooks';
import DisclaimerModal from '../DisclaimerModal/DisclaimerModal';

const { Title } = Typography;

/**
 * @desc Gather general info.
 * @author Jacob Wright <jwright@ecg-hq.com>
 * @prop {Object}  state - global state of patient data
 * @prop {function} setQuestionsData - creates object of general info answers
 */
const GeneralInfo = ({ setQuestionsData, state, dispatch }) => {
  const [showEthnicity, setShowEthnicity] = useState(null);
  const [otherEthnicity, setOtherEthnicity] = useState(null);
  const ethArray = ['Black/African American', 'Hispanic/Latino', 'White/Caucasian', 'Native America/American Indian', 'Asian'];
  const [ethnicity, setEthnicity] = useState([]);
  const [heightFeet, setHeightFeet] = useState(null);
  const [heightInches, setHeightInches] = useState(null);
  const [weight, setWeight] = useState(null);
  const [education, setEducation] = useState(null);
  const [income, setIncome] = useState(null);

  const handleEthnicityChange = (checkedValues) => {
    if (checkedValues.includes('Other')) {
      setEthnicity(
        [...checkedValues.filter(x => (ethArray.includes(x))
        && !(x === 'Other')
        && !(x === null)), otherEthnicity],
      );
    } else {
      setEthnicity(checkedValues.filter(val => (ethArray.includes(val))
        && !(val === 'Other')));
    }
  }

  const handleHeightFeetChange = (e) => {
    if (e > 0 && e <= 10) {
      setHeightFeet(e);
    }
  }

  const handleHeightInchesChange = (e) => {
    if (e > 0 && e <= 12) {
      setHeightInches(e);
    }
  }

  useEffect(() => {
    console.log(
      [
        ethnicity,
        heightFeet,
        heightInches,
        weight,
        education,
        income,
      ],
    );
    setQuestionsData(
      {
        ethnicity: ethnicity.length !== 0 ? ethnicity : null,
        height: heightInches !== null && heightFeet !== null ? (heightFeet * 12) + heightInches : null,
        weight,
        education,
        income,
      },
    );
  },
  [
    ethnicity,
    heightFeet,
    heightInches,
    weight,
    education,
    income,
  ]);
  const [localState, setLocalState] = useLocalStorage('localState', state);
  useEffect(() => {
    if (JSON.stringify(state.introQuestions) !== '{}') setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);

  useEffect(() => {
    if (JSON.stringify(state.introQuestions) === '{}') {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);
  useEffect(() => {
    if (localState.patientData === null) {
      navigate('/404');
    }
  }, []);
  return (
    <div className="introduction__container">
      <DisclaimerModal />
      <Title className="pro-health-or-addison__title" level={2}>
          General Info
      </Title>
      <Divider />

      <h3 className="question">Ethnicity:</h3>
      <h4>(select all that apply)</h4>
      <Checkbox.Group
        className="input"
        onChange={handleEthnicityChange}
      >
        <Col span={80}>
          <Checkbox value="White/Caucasian">White/Caucasian</Checkbox>
          <br />
          <Checkbox value="Hispanic/Latino">Hispanic/Latino</Checkbox>
          <br />
          <Checkbox value="Black/African American">Black/African American</Checkbox>
          <br />
          <Checkbox value="Native America/American Indian">Native America/American Indian</Checkbox>
          <br />
          <Checkbox value="Asian">Asian/Pacific Islander</Checkbox>
          <br />
          <Checkbox
            value="Other"
            onChange={() => (
              showEthnicity !== true
                ? setShowEthnicity(true)
                : (() => {
                  setShowEthnicity(false);
                })())
              }
          >
            Other
          </Checkbox>
          {showEthnicity === true
            ? (
              <Input
                style={{ width: 100, marginLeft: 10 }}
                maxLength={45}
                onChange={(val) => {
                  val.persist();
                  setOtherEthnicity(val.currentTarget.value);
                  setEthnicity(oldState => [...oldState.filter(x => (ethArray.includes(x)) && !(x === 'Other') && !(x === null)), val.target.value]);
                }}
                value={otherEthnicity}
              />
            )
            : null}
        </Col>
      </Checkbox.Group>

      <h3 className="question">Height:</h3>
      <InputNumber
        className="input"
        onChange={handleHeightFeetChange}
        placeholder="feet"
        addonAfter="ft."
        value={heightFeet}
      />
      <InputNumber
        className="input"
        onChange={handleHeightInchesChange}
        placeholder="inches"
        addonAfter="in."
        value={heightInches}
      />

      <h3 className="question">Weight:</h3>
      <InputNumber
        className="input"
        onChange={e => setWeight(e)}
        placeholder="150 lbs."
        addonAfter="lbs."
        min={0}
        max={1900}
        step={10}
      />

      <h3 className="question">Highest Level of Education:</h3>
      <Radio.Group
        className="general-info__input"
        onChange={e => setEducation(e.target.value)}
      >

        <Radio value="Some High School">Some High School</Radio>
        <Radio value="High School Graduate">High School Graduate</Radio>
        <Radio value="Vocational Degree">Vocational Degree</Radio>
        <Radio value="Some College">Some College</Radio>
        <Radio value="Associate Degree">Associate Degree</Radio>
        <Radio value={"Bachelor's Degree"}>Bachelor's Degree</Radio>
        <Radio value="Graduate Degree">Graduate Degree</Radio>
        <Radio value="Prefer Not To Say">Prefer Not To Say</Radio>

        <Radio value="Other">
              Other
          {education === 'Other'
            ? <Input maxLength={70} className="input" style={{ float: 'right', width: 100, marginLeft: 10 }} />
            : null}
        </Radio>
      </Radio.Group>

      {/* <h3 className="question">Annual Income:</h3>
      <InputNumber
        className="input"
        onChange={e => setIncome(e)}
        placeholder="$X0,000/year"
        min={0}
        step={5000}
        formatter={value => `$ ${value}`}
      /> */}
      <Divider />
    </div>
  );
};

export default GeneralInfo;
