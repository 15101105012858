
import React from 'react';
import {
  Result, Button, Icon, Typography,
} from 'antd';
import { navigate } from '@reach/router';

const { Paragraph, Text } = Typography;
const RefreshDetected = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '4%',
  }}
  >
    <Result
      status="404"
      title="404"
      subTitle="Sorry, there was an error."
      extra={(
        <Button
          type="primary"
          size="large"
          onClick={() => {
            navigate('/');
            window.location.reload();
          }}
        >
          Return to Home
        </Button>
)}
    />
    <Paragraph>
      <Text
        strong
        style={{
          fontSize: 16,
        }}
      >
          Some of the causes may be as follows:
      </Text>
    </Paragraph>
    <Paragraph>
      <Icon style={{ color: 'red' }} type="close-circle" />
      {' '}
Loss of internet connection
      {' '}
    </Paragraph>
    <Paragraph>
      <Icon style={{ color: 'red' }} type="close-circle" />
      {' '}
Page was refreshed
      {' '}
    </Paragraph>
  </div>
);

export default RefreshDetected;
