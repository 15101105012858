const globalStateReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_STATE_TO_LOCAL_STORAGE':
      return { ...state, ...action.payload };
    case 'UPDATE_LOGGED_IN_DEALER':
      return { ...state, loggedInDealerID: action.payload };
    case 'UPDATE_XMIT':
      return { ...state, xmitID: action.payload };
    case 'UPDATE_MEDICAL_CONDITION':
      return { ...state, medicalCondition: action.payload };
    case 'UPDATE_HEIGHTFEET':
      return { ...state, heightFeet: action.payload };
    case 'UPDATE_HEIGHTINCHES':
      return { ...state, heightInches: action.payload };
    case 'UPDATE_WEIGHT':
      return { ...state, weight: action.payload };
    case 'UPDATE_BLOODTYPE':
      return { ...state, bloodType: action.payload };
    case 'UPDATE_BLOODTYPE_RH':
      return { ...state, bloodTypeRH: action.payload };
    case 'UPDATE_ALLERGIES':
      return { ...state, allergies: action.payload };
    case 'UPDATE_SURGERIES':
      return { ...state, surgeries: action.payload };
    case 'UPDATE_PATIENT_DATA':
      return { ...state, patientData: action.payload };
    case 'UPDATE_PRO_HEALTH_ONLY':
      return { ...state, proHealthOnly: action.payload };
    case 'UPDATE_DEVICES':
      return { ...state, devices: action.payload };
    case 'UPDATE_INTRO_QUESTIONS':
      return {
        ...state,
        introQuestions: {
          ...state.introQuestions,
          ...action.payload,
        },
      };
    case 'UPDATE_REMINDERS':
      return {
        ...state,
        reminders:
          action.payload,
      };
    case 'UPDATE_STEP_PROGRESS':
      return {
        ...state,
        stepProgress: [state.stepProgress.slice(0, action.payload),
          'complete', state.stepProgress.slice(action.payload, state.stepProgress.length)],
      };
    case 'UPDATE_WEIGHT_THRESHOLD':
      return {
        ...state,
        thresholds: {
          ...state.thresholds,
          weight: action.payload,
        },
      };
    case 'UPDATE_GLUCOSE_THRESHOLD':
      return {
        ...state,
        thresholds: {
          ...state.thresholds,
          glucose: action.payload,
        },
      };
    case 'UPDATE_THERMOMETER_THRESHOLD':
      return {
        ...state,
        thresholds: {
          ...state.thresholds,
          thermometer: action.payload,
        },
      };
    case 'UPDATE_OXYGEN_THRESHOLD':
      return {
        ...state,
        thresholds: {
          ...state.thresholds,
          oxygen: action.payload,
        },
      };
    case 'UPDATE_BLOOD_PRESSURE_THRESHOLD':
      return {
        ...state,
        thresholds: {
          ...state.thresholds,
          bloodPressure: action.payload,
        },
      };
    default:
      return state;
  }
};
export default globalStateReducer;
