import React, { useState, useEffect } from 'react';
import {
  Typography, Radio, InputNumber, Checkbox, Col, Divider,
} from 'antd';
import { useLocalStorage } from 'standard-hooks';
import { navigate } from '@reach/router';

const { Title } = Typography;

/**
 * @desc Addison introduction questions addressing nutrition, sleep, and elimination.
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @prop {Object} state - global state of patient data
 * @prop {function} setQuestionsData - Creating an object of daily nutrition page answers.
 */
const DailyNutrition = ({ setQuestionsData, state = {}, dispatch }) => {
  const [dietRestriction, setDietRestriction] = useState(null);
  const [fluidRestriction, setFluidRestriction] = useState(null);
  const [sleepingDifficulties, setSleepingDifficulties] = useState(null);
  const [sleepingAids, setSleepingAids] = useState(null);
  const [restroomNight, setRestroomNight] = useState(null);
  const [restroomFrequency, setRestroomFrequency] = useState(null);
  const [bowelComplaints, setBowelComplaints] = useState(null);
  const [urinaryComplaints, setUrinaryComplaints] = useState(null);

  useEffect(() => {
    console.log(
      [
        dietRestriction,
        fluidRestriction,
        sleepingDifficulties,
        sleepingAids,
        restroomNight,
        restroomFrequency,
        bowelComplaints,
        urinaryComplaints,
      ],
    );
    setQuestionsData(
      {
        dietRestriction,
        fluidRestriction,
        sleepingDifficulties,
        sleepingAids,
        restroomNight,
        restroomFrequency,
        bowelComplaints,
        urinaryComplaints,
      },
    );
  },
  [
    dietRestriction,
    fluidRestriction,
    sleepingDifficulties,
    sleepingAids,
    restroomNight,
    restroomFrequency,
    bowelComplaints,
    urinaryComplaints,
  ]);
  const [localState, setLocalState] = useLocalStorage('localState', state);
  useEffect(() => {
    if (JSON.stringify(state.introQuestions) !== '{}') setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);

  useEffect(() => {
    if (JSON.stringify(state.introQuestions) === '{}') {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);
  useEffect(() => {
    if (localState.patientData === null) {
      navigate('/404');
    }
  }, []);
  return (
    <div className="introduction__container">
      <Title className="pro-health-or-addison__title" level={2}>
          General Info
      </Title>
      <Divider />

      <h3 className="question">Do you have any diet restrictions?</h3>
      <Radio.Group
        onChange={e => setDietRestriction(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>

      <h3 className="question">Do you have any fluid restrictions?</h3>
      <Radio.Group
        onChange={e => setFluidRestriction(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
      <Divider />

      <Title className="pro-health-or-addison__title" level={2}>
          Daily Sleep Patterns
      </Title>
      <Divider />

      <h3 className="question">Do you have difficulty sleeping at night?</h3>
      <Radio.Group
        onChange={e => setSleepingDifficulties(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>

      <h3 className="question">Do you use sleep aids?</h3>
      <Radio.Group
        onChange={e => setSleepingAids(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>

      <h3 className="question">Do you get up at night to use the restroom?</h3>
      <Radio.Group
        onChange={e => setRestroomNight(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
        {restroomNight === true
          ? (
            <div>
              <h3 className="question">How many times a night:</h3>
              <InputNumber
                onChange={e => setRestroomFrequency(e)}
                min={0}
                maxLength={3}
              />
            </div>
          )
          : null
        }
      </Radio.Group>
      <Divider />

      <Title className="pro-health-or-addison__title" level={2}>
          Daily Elimination
      </Title>
      <Divider />

      <h3 className="question">Bowel Complaints:</h3>
      <h4>(select all that apply)</h4>
      <Checkbox.Group
        onChange={e => setBowelComplaints(e)}
      >
        <Col span={80}>
          <Checkbox value="Diarrhea">Diarrhea</Checkbox>
          <br />
          <Checkbox value="Constipation">Constipation</Checkbox>
          <br />
          <Checkbox value="Melaena">Melaena</Checkbox>
          <br />
          <Checkbox value="Other">None</Checkbox>
        </Col>
        <br />
      </Checkbox.Group>

      <h3 className="question">Urinary Complaints:</h3>
      <h4>(select all that apply)</h4>
      <Checkbox.Group
        onChange={e => setUrinaryComplaints(e)}
      >
        <Col span={80}>
          <Checkbox value="Frequency">Frequency</Checkbox>
          <br />
          <Checkbox value="Dysuria">Dysuria</Checkbox>
          <br />
          <Checkbox value="Haematuria">Haematuria</Checkbox>
          <br />
          <Checkbox value="Nocuria">Nocuria</Checkbox>
          <br />
          <Checkbox value="Incontinence">Incontinence</Checkbox>
          <br />
          <Checkbox value="Other">None</Checkbox>
        </Col>
        <br />
      </Checkbox.Group>
    </div>
  );
};

export default DailyNutrition;
