import React, {
  useState,
  useEffect,
} from 'react';
import {
  Typography, Button, Spin,
} from 'antd';
import ReactPlayer from 'react-player';
import Fade from 'react-reveal/Fade';
import { useLocalStorage } from 'standard-hooks';
import { API, graphqlOperation } from 'aws-amplify';
import formatVitalsData from '../../API/formatVitalsData';
import subscription from '../../API/graphql/subscribeToAneltoEvents';
import Threshold from '../Threshold/Threshold';

const { Title } = Typography;

/**
 * @desc Go through tutorial and take blood pressure for the first time
 * @author Dakota Marquez <dmarquez@ecg-hq.com>
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @prop {Object}  state - global state of patient data
 * @prop {function} dispatch - a reducer of state object
 * @prop {function} setPath - defines url path
 */

const BloodPressure = ({ setPath, state = {}, dispatch }) => {
  const defaultState = { Systolic: null, Diastolic: null };
  const [reading, setReading] = useState(defaultState);

  // the states of what content is being rendered
  const [viewThreshold, setViewThreshold] = useState(true);
  const [readingView, setReadingView] = useState(true);

  /**
 * @desc Blood Pressure useEffect - 
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @returns 
 */

  useEffect(() => {
    const subscribe = API.graphql(graphqlOperation(subscription, { Account: state.xmitID }))
      .subscribe({
        next: (data) => {
          console.log('Vitals Data', formatVitalsData(data));
          setReading(formatVitalsData(data));
        },
      });
    return () => subscribe.unsubscribe();
  }, [state.xmitID]);
  const takeReading = () => {
    if (reading.Systolic !== null){
    console.log('blood pressure reading successfully taken');
    setReadingView(false);
    setPath('/complete-activation');
  }};
  const resetReading = () => {
    console.log('resetting blood pressure reading');
    setReadingView(true);
    setReading(defaultState);
  };
  const [localState, setLocalState] = useLocalStorage('localState', state);
  useEffect(() => {
    if (JSON.stringify(state.patientData) !== 'null') setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);

  useEffect(() => {
    if (JSON.stringify(state.patientData) === 'null') {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_BLOOD_PRESSURE_THRESHOLD',
      payload: { systolic: { min: '90', max: '120' }, diastolic: { min: '60', max: '80' } },
    });
  }, []);
  useEffect(() => {
    takeReading();
  }, [reading.Systolic]);
  return (
    <div className="activation-tutorials__container">
      <Title level={2}>
          Blood Pressure Tutorial
      </Title>
      <div className="responsive-image">
        <ReactPlayer
          url="https://addison-project-anim-files.s3.amazonaws.com/Videos/VitalsTutorials/BPTutorial_v03.mp4"
          controls
          height="100%"
          width="100%"
        />
      </div>
      <br />
      <Fade>
        <div>
          <Title level={4} className="activation-tutorials__text">
            Please use your blood pressure cuff when you are ready.
            <br />
            Your reading is:
          </Title>
          {(reading.Systolic === null || reading.Diastolic === null)
            ? (
              <div className="activation-tutorials__spinner">
                {' '}
                <Spin />
                {' '}
              </div>
            )
            : (
              <div className="activation-tutorials__vital-feedback">
                {reading.Systolic}/{reading.Diastolic} mm Hg
                {' '}
              </div>
            )}
          <div className="activation-tutorials__text">
            {(reading.Systolic !== null || reading.Diastolic !== null)
              ? (
                <>
                  <p>
                    Your thresholds have been set to the recommended standard:
                    <br />
                    90/60 mm Hg to 120/80 mm Hg
                  </p>
                </>
              )
              : null}
          </div>
          <div hidden={readingView}>
            <Button
              type="link"
              // shows threshold input fields by rendering Threshold.jsx component
              onClick={() => setViewThreshold(false)}
              hidden={readingView}
              className="activation-tutorials__threshold-button"
            >
              Set Personal Thresholds
            </Button>
            <div className="activation-tutorials__threshold-reading">
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={() => resetReading()}
              >
              Retake Reading
              </Button>
            </div>
          </div>
        </div>
      </Fade>
      <br />
      <Threshold vitalType="systolic" secondVitalType="diastolic" dispatch={dispatch} vitalName="BLOOD_PRESSURE" hidden={viewThreshold} />
    </div>
  );
};

export default BloodPressure;
