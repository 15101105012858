import axios from 'axios';
import _ from 'lodash';


const postRapidActivate = async (state) => {
  const responsiblePartyObject = state.patientData.data.contacts;
  console.log("responsiblePartyObject", responsiblePartyObject);
  let responsiblePartyArray = [];
  responsiblePartyArray[0] = {
    name: responsiblePartyObject.responsibleparty1.rpname,
    phone: responsiblePartyObject.responsibleparty1.rpphone,
    relationship: responsiblePartyObject.responsibleparty1.rprelationship,
  };
  if (responsiblePartyObject.responsibleparty2) {
    responsiblePartyArray[1] = {
      name: responsiblePartyObject.responsibleparty2.rpname,
      phone: responsiblePartyObject.responsibleparty2.rpphone,
      relationship: responsiblePartyObject.responsibleparty2.rprelationship,
    };
  }
  if (responsiblePartyObject.responsibleparty3) {
    responsiblePartyArray[2] = {
      name: responsiblePartyObject.responsibleparty3.rpname,
      phone: responsiblePartyObject.responsibleparty3.rpphone,
      relationship: responsiblePartyObject.responsibleparty3.rprelationship,
    };
  }
  if (responsiblePartyObject.responsibleparty4) {
    responsiblePartyArray[3] = {
      name: responsiblePartyObject.responsibleparty4.rpname,
      phone: responsiblePartyObject.responsibleparty4.rpphone,
      relationship: responsiblePartyObject.responsibleparty4.rprelationship,
    };
  }
  console.log('tempArray', responsiblePartyArray)
  try {
    const body = {
      data: {
        internalid: state.xmitID,
        customerPhone: state.patientData.data.customerPhone || '1234567890',
        // eslint-disable-next-line prefer-template
        siteName: state.patientData.data.lastName + ', ' + state.patientData.data.firstName,
        region: state.patientData.data.address.state,
        siteType: 'GPS',
        address: {
          street: state.patientData.data.address.street,
          city: state.patientData.data.address.city,
          state: state.patientData.data.address.state,
          zip: state.patientData.data.address.zip,
        },
        sourceID: state.patientData.sourceid,
        actionPlan: state.patientData.actionplan,
        allergies: state.allergies,
        medicalCondition: state.medicalCondition,
        surgeries: state.surgeries,
        firstName: state.patientData.data.firstName,
        lastName: state.patientData.data.lastName,
        dealerID: state.loggedInDealerID,
        dob: state.patientData.data.dob,
        preferredHospital: state.patientData.data.preferredhospital,
        medicationReminders: state.reminders.medReminder,
        activityReminders: state.reminders.activityReminder,
        doctor: {
          ...state.patientData.data.contacts.preferreddoctor.doctorname &&
          {
            name: state.patientData.data.contacts.preferreddoctor.doctorname
          },
          ...state.patientData.data.contacts.preferreddoctor.doctornamephone &&
          {
            phone: state.patientData.data.contacts.preferreddoctor.doctorphone
          },
        },
        username: state.patientData.id,
        ...state.patientData.bloodType && { bloodType: state.patientData.bloodType },
        ...state.patientData.specialConcerns && { specialConcerns: state.patientData.specialConcerns },
        contacts: responsiblePartyArray,
      },
    };
    console.log('body', body);
    const response = await axios.post('https://9yrs4abhs7.execute-api.us-east-1.amazonaws.com/dev', {
      ...body
    });
    return response;
  } catch (e) {
    return e;
  }
};
export default postRapidActivate;
