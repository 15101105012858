import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import Weight from '../components/Weight/Weight';

const WeightPage = ({ state = {}, dispatch }) => {
  const [path, setPath] = useState('');
  return (
    <Layout
      state={state}
      previousPath="/vitals"
      dispatch={dispatch}
      nextPath={path}
      back
    >
      <div className="app__content-box">
        <Weight state={state} dispatch={dispatch} setPath={setPath} />
      </div>
    </Layout>
  );
};
export default WeightPage;
