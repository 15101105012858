import React, {
  useState,
  useEffect,
} from 'react';
import {
  Typography, Button, Spin,
} from 'antd';
import ReactPlayer from 'react-player';
import { useLocalStorage } from 'standard-hooks';
import Fade from 'react-reveal/Fade';
import _ from 'lodash';
import { API, graphqlOperation } from 'aws-amplify';
import vitalsPath from '../../functions/vitalsPath';
import formatVitalsData from '../../API/formatVitalsData';
import subscription from '../../API/graphql/subscribeToAneltoEvents';
import Threshold from '../Threshold/Threshold';

const { Title } = Typography;

/**
* @desc weight scale tutorial and first reading in the Activation section
* @author Dakota Marquez <dmarquez@ecg-hq.com>
* @author Hector Rodriguez <hrodriguez@ecg-hq.com>
* @prop {Object} state - global state of patient data
* @prop {function} dispatch - a reducer of state object
* @prop {function} setPath - defines url path
*/

const Weight = ({ setPath, state = {}, dispatch }) => {
  const defaultState = { Weight: null };
  const [reading, setReading] = useState(defaultState);

  // the states of what content is being rendered
  const [viewThreshold, setViewThreshold] = useState(true);
  const [readingView, setReadingView] = useState(true);
  
  /**
 * @desc Weight useEffect - 
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @returns 
 */

  useEffect(() => {
    const subscribe = API.graphql(graphqlOperation(subscription, { Account: state.xmitID }))
      .subscribe({
        next: (data) => {
          console.log('Vitals Data', formatVitalsData(data));
          setReading(formatVitalsData(data));
        },
      });
    return () => subscribe.unsubscribe();
  }, [state.xmitID]);
  const takeReading = () => {
    if (reading.Weight !== null){
    console.log('weight reading successfully taken');
    setReadingView({ hidden: false, disabled: true });
    const remainingReadings = vitalsPath(
      _.filter(
        state.devices, device => device !== 'weight' && device !== 'glucose',
      ),
    );
    if (remainingReadings === undefined) {
      setPath('/complete-activation');
    } else {
      setPath(remainingReadings);
    }
  }};
  const resetReading = () => {
    console.log('resetting weight reading');
    setReadingView(true);
    setReading(defaultState);
  };
  const [localState, setLocalState] = useLocalStorage('localState', state);
  useEffect(() => {
    if (JSON.stringify(state.patientData) !== 'null') setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);

  useEffect(() => {
    if (JSON.stringify(state.patientData) === 'null') {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);
  useEffect(() => {
    dispatch({
      type: 'UPDATE_WEIGHT_THRESHOLD',
      payload: { min: null, max: null },
    });
  }, []);
  useEffect(() => {
    takeReading();
  }, [reading.Weight]);
  return (
    <div className="activation-tutorials__container">
      <Title level={2}>
            Weight Scale Tutorial
      </Title>
      <div className="responsive-image">
        <ReactPlayer
          url="https://addison-project-anim-files.s3.amazonaws.com/Videos/VitalsTutorials/ScaleTutorial_v03.mp4"
          controls
          height="100%"
          width="100%"
        />
      </div>
      <br />
      <Fade>
        <div hidden={readingView.hidden}>
        <Title level={4} className="activation-tutorials__text">
          Please use your scale when you are ready.
          <br />
          Your reading is:
        </Title>
          { reading.Weight === null ? (
            <div className="activation-tutorials__spinner">
              {' '}
              <Spin />
              {' '}
            </div>
          )
            : (
              <div className="activation-tutorials__vital-feedback">
                {JSON.stringify(reading.Weight)}
                {' '}
              </div>
            )}
          <br />
          <div hidden={readingView}>
            <Button
              type="link"
              // shows threshold input fields by rendering Threshold.jsx component
              onClick={() => setViewThreshold(false)}
              hidden={readingView}
              className="activation-tutorials__threshold-button"
            >
              Set Personal Thresholds
            </Button>
            <div className="activation-tutorials__threshold-reading">
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={resetReading}
              >
                Retake Reading
              </Button>
            </div>
          </div>
        </div>
      </Fade>
      <br />
      <Threshold vitalType="weight" dispatch={dispatch} vitalName="WEIGHT" hidden={viewThreshold} />
    </div>
  );
};

export default Weight;
