import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import RiskAssessment from '../components/RiskAssessmentSurvey/RiskAssessmentSurvey';

const RiskAssessmentPage = ({ state = {}, dispatch }) => {
  const [questionsData, setQuestionsData] = useState({});
  return (
    <Layout
      state={state}
      previousPath="/complete-activation"
      dispatch={dispatch}
      nextPath="/emergency-contact"
      introduction
      questionsData={questionsData}
    >
      <div className="app__content-box">
        <RiskAssessment state={state} dispatch={dispatch} setQuestionsData={setQuestionsData} />
      </div>
    </Layout>
  );
};

export default RiskAssessmentPage;