import React, { useEffect, useState } from 'react';
import {
  Typography, Input, Radio, Divider, Button,
} from 'antd';
import _ from 'lodash';
import { useLocalStorage } from 'standard-hooks';
import { navigate } from '@reach/router';

const { Title } = Typography;

/**
 * @desc Get Healthcare provider information.
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @prop {Object}  state - global state of patient data
 * @prop {function} setQuestionsData - creates object of healthcare provider answers
 */
const HealthcareProvider = ({ setQuestionsData, state, dispatch }) => {
  const [primaryCare, setPrimaryCare] = useState(null);
  const [pharmacyName, setPharmacyName] = useState(null);
  const [pharmacyConcerns, setPharmacyConcerns] = useState(null);
  const [secondaryCare, setSecondaryCare] = useState([{}]);

  useEffect(() => {
    console.log(
      [
        primaryCare,
        secondaryCare,
      ],
    );
    setQuestionsData(
      {
        primaryCare,
        secondaryCare: secondaryCare !== [{}] ? secondaryCare : null,
        pharmacyName,
        pharmacyConcerns,
      },
    );
  },
  [
    primaryCare,
    secondaryCare,
  ]);
  const [localState, setLocalState] = useLocalStorage('localState', state);
  useEffect(() => {
    if (JSON.stringify(state.introQuestions) !== '{}') setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);
  useEffect(() => {
    if (JSON.stringify(state.introQuestions) === '{}') {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);
  useEffect(() => {
    if (localState.patientData === null) {
      navigate('/404');
    }
  }, []);
  return (
    <div className="introduction__container">

      <Title className="pro-health-or-addison__title" level={2}>
          Healthcare Provider
      </Title>
      <Divider />

      {state.patientData
        ? (
          <div>
            <h3>Primary Care Information</h3>
            <div>{`Primary Care Name: ${state.patientData.data.contacts.preferreddoctor ? state.patientData.data.contacts.preferreddoctor.doctorname : 'N/A'}`}</div>
            <div>{`Primary Care Phone Number: ${state.patientData.data.contacts.preferreddoctor ? state.patientData.data.contacts.preferreddoctor.doctorphone : 'N/A'}`}</div>
            <div>{`Preferred Hospital: ${state.patientData.data.preferredhospital ? state.patientData.data.preferredhospital : 'N/A'}`}</div>
            <div>{`Special Concerns: ${state.patientData.specialConcerns ? state.patientData.specialConcerns : 'N/A'}`}</div>
            <div>{`Blood Type: ${state.patientData.bloodType ? state.patientData.bloodType : 'N/A'}`}</div>
            <br />
          </div>
        )
        : null}
      <h3 className="question">Is the Primary Care information correct?</h3>
      <Radio.Group
        onChange={e => setPrimaryCare(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
      <Divider />

      <Title className="pro-health-or-addison__title" level={2}>
          Secondary Healthcare Provider
      </Title>
      <Divider />
      <div>*Please enter as many that apply.</div>
      <div>Example: Cardiologist, Orthopedic, Chiropractor, ect.</div>
      <br />
      {secondaryCare.map((something, idx) => (
        <div
          key={`item${idx}`}
          className="provider-container"
        >
          <h3>
          Secondary Healthcare
            {' '}
            {idx + 1}
          </h3>

          <h3 className="question">Care Provider Name</h3>
          <Input
            placeholder="Full Name"
            className="provider__input-field"
            maxLength={70}
            value={secondaryCare[idx].name}
            onChange={(e) => {
              setSecondaryCare([
                ...secondaryCare.slice(0, idx),
                {
                  ...secondaryCare[idx],
                  name: e.target.value,
                },
                ...secondaryCare.slice(idx + 1, secondaryCare.length),
              ]);
            }}
          />

          <h3 className="question">Care Provider Phone Number</h3>
          <Input
            placeholder="(xxx)-xxx-xxx"
            maxLength={10}
            className="provider__input-field"
            value={secondaryCare[idx].phoneNumber}
            onChange={(e) => {
              setSecondaryCare([
                ...secondaryCare.slice(0, idx),
                {
                  ...secondaryCare[idx],
                  phoneNumber: e.target.value,
                },
                ...secondaryCare.slice(idx + 1, secondaryCare.length),
              ]);
            }}
          />

          <h3 className="question">Is this care provider a specialist?</h3>
          <Radio.Group
            value={secondaryCare[idx].specialistYN}
            onChange={(e) => {
              setSecondaryCare([
                ...secondaryCare.slice(0, idx),
                {
                  ...secondaryCare[idx],
                  specialistYN: e.target.value,
                },
                ...secondaryCare.slice(idx + 1, secondaryCare.length),
              ]);
            }}
          >
            <Radio value>Yes</Radio>
            <Radio value={false}>No</Radio>
            {secondaryCare[idx].specialistYN === true
              ? (
                <div>
                  <h3>Specialty:</h3>
                  <Input
                    placeholder="Surgeon"
                    maxLength={100}
                    className="provider__input-field"
                    value={secondaryCare[idx].specialty}
                    onChange={(e) => {
                      setSecondaryCare([
                        ...secondaryCare.slice(0, idx),
                        {
                          ...secondaryCare[idx],
                          specialty: e.target.value,
                        },
                        ...secondaryCare.slice(idx + 1, secondaryCare.length),
                      ]);
                    }}
                  />
                </div>
              )
              : null
            }
          </Radio.Group>
          <br />
          <Button
            className="provider-center-button"
            value={idx}
            type="danger"
            ghost
            shape="round"
            onClick={(e) => {
              e.persist();
              setSecondaryCare(
                // inequality must remain != for full functionality
                // types don't match. don't change to !==
                [...secondaryCare.filter((value, idx) => idx != e.target.value)],
              );
            }}
          >
  Remove Secondary Care
          </Button>
          <Divider />
        </div>
      ))}
      <div className="provider-container">
        <Button
          className="provider-center-button"
          type="primary"
          shape="round"
          onClick={(e) => {
            setSecondaryCare(
              [...secondaryCare, e.target.value],
            );
          }}
        >
      Add Secondary Care
        </Button>
      </div>

      <Divider />
      <h3>
        Preferred Pharmacy:
      </h3>
      <p className="question">Pharmacy Name:</p>
      <Input
        className="medications__medication-inputs"
        placeholder="Name of preferred pharmacy"
        value={pharmacyName}
        maxLength={70}
        onChange={(e) => {
          setPharmacyName(e.target.value);
        }}
      />
      <p className="question">Do you have any concerns getting prescriptions refilled?</p>
      <Radio.Group
        className="medications__radio-group"
        value={pharmacyConcerns}
        onChange={(e) => {
          setPharmacyConcerns(e.target.value);
        }}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
    </div>
  );
};

export default HealthcareProvider;
