const MedicalConditions = [
  {
    MedicalConditionCode: '1',
    MedicalConditionDescription: 'Asthma',
  },
  {
    MedicalConditionCode: '10',
    MedicalConditionDescription: 'Atrial Fibrillation',
  },
  {
    MedicalConditionCode: '11',
    MedicalConditionDescription: 'High Cholesterol',
  },
  {
    MedicalConditionCode: '12',
    MedicalConditionDescription: 'Arthritis; Rheumatoid',
  },
  {
    MedicalConditionCode: '13',
    MedicalConditionDescription: 'Osteoporosis',
  },
  {
    MedicalConditionCode: '14',
    MedicalConditionDescription: "Parkinson's Disease",
  },
  {
    MedicalConditionCode: '15',
    MedicalConditionDescription: 'Macular Degeneration',
  },
  {
    MedicalConditionCode: '16',
    MedicalConditionDescription: 'Glaucoma',
  },
  {
    MedicalConditionCode: '17',
    MedicalConditionDescription: 'Cataracts',
  },
  {
    MedicalConditionCode: '18',
    MedicalConditionDescription: 'Atherosclerosis',
  },
  {
    MedicalConditionCode: '19',
    MedicalConditionDescription: 'Chronic Obstructive Pulmonary Disease (COPD)',
  },
  {
    MedicalConditionCode: '2',
    MedicalConditionDescription: "Dementia; Alzheimer's Disease",
  },
  {
    MedicalConditionCode: '20',
    MedicalConditionDescription: 'Anemia',
  },
  {
    MedicalConditionCode: '21',
    MedicalConditionDescription: 'Depression',
  },
  {
    MedicalConditionCode: '3',
    MedicalConditionDescription: 'Diabetes',
  },
  {
    MedicalConditionCode: '4',
    MedicalConditionDescription: 'Heart',
  },
  {
    MedicalConditionCode: '5',
    MedicalConditionDescription: 'Cancer',
  },
  {
    MedicalConditionCode: '6',
    MedicalConditionDescription: 'Respiratory',
  },
  {
    MedicalConditionCode: '7',
    MedicalConditionDescription: 'Hypertension (high blood pressure)',
  },
  {
    MedicalConditionCode: '8',
    MedicalConditionDescription: 'Stroke; CVA/TIA',
  },
  {
    MedicalConditionCode: '9',
    MedicalConditionDescription: 'Seizures',
  },
  {
    MedicalConditionCode: '0',
    MedicalConditionDescription: 'Other',
  },
];

export default MedicalConditions;
