import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import TestSignal from '../components/TestSignal/TestSignal';

const TestSignalPage = ({ state = {}, dispatch }) => {
  const [path, setPath] = useState('');
  return (
    <Layout
      state={state}
      previousPath="/rapid-activation"
      dispatch={dispatch}
      nextPath={path}
    >
      <div className="app__content-box">
        <TestSignal
          state={state}
          dispatch={dispatch}
          setPath={setPath}
        />
      </div>
    </Layout>
  );
};
export default TestSignalPage;
