const Allergies = [
  {
    AllergyCode: '1',
    AllergyDescription: 'Aspirin',
  },
  {
    AllergyCode: '10',
    AllergyDescription: 'Mold',
  },
  {
    AllergyCode: '11',
    AllergyDescription: 'Dander',
  },
  {
    AllergyCode: '12',
    AllergyDescription: 'Insect Sting',
  },
  {
    AllergyCode: '13',
    AllergyDescription: 'Insulin',
  },
  {
    AllergyCode: '14',
    AllergyDescription: 'Barbituates',
  },
  {
    AllergyCode: '15',
    AllergyDescription: 'Anticonvulsants',
  },
  {
    AllergyCode: '16',
    AllergyDescription: 'Penicillin',
  },
  {
    AllergyCode: '2',
    AllergyDescription: 'Iodine',
  },
  {
    AllergyCode: '3',
    AllergyDescription: 'Pain Medications',
  },
  {
    AllergyCode: '4',
    AllergyDescription: 'Antibiotics',
  },
  {
    AllergyCode: '5',
    AllergyDescription: 'Food',
  },
  {
    AllergyCode: '6',
    AllergyDescription: 'Latex',
  },
  {
    AllergyCode: '7',
    AllergyDescription: 'Sulfa Drugs',
  },
  {
    AllergyCode: '8',
    AllergyDescription: 'Seasonal',
  },
  {
    AllergyCode: '9',
    AllergyDescription: 'Pollen',
  },
  {
    AllergyCode: '0',
    AllergyDescription: 'Other',
  },
];
export default Allergies;
