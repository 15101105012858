import React, { useState, useEffect } from 'react';
import {
  Typography, Input, Radio, DatePicker, Divider, Button, Icon,
} from 'antd';
import { navigate } from '@reach/router';
import { useLocalStorage } from 'standard-hooks';
import Fade from 'react-reveal/Fade';

const { Title } = Typography;

/**
 * @desc Gather general health data.
 * @author Jacob Wright <jwright@ecg-hq.com>
 * @prop {Object}  state - global state of patient data
 * @prop {function} setQuestionsData - creates object of general health answers
 */
const GeneralHealth = ({ setQuestionsData, state = {}, dispatch }) => {
  const [tobacco, setTobacco] = useState(null);
  const [tobaccoUse, setTobaccoUse] = useState(null);
  const [tobaccoQuit, setTobaccoQuit] = useState(null);
  const [substancesYN, setSubstancesYN] = useState(null);
  const [substances, setSubstances] = useState([null]);
  const [substancesFrequency, setSubstancesFrequency] = useState(null);
  const [substancesQuit, setSubstancesQuit] = useState(null);
  const [hearing, setHearing] = useState(null);
  const [hearingPartial, setHearingPartial] = useState(null);
  const [hearingAid, setHearingAid] = useState(null);
  const [vision, setVision] = useState(null);
  const [visionPartial, setVisionPartial] = useState(null);
  const [visionAid, setVisionAid] = useState(null);
  const [visionColor, setVisionColor] = useState(null);

  const reg = /^\d+$/;

  const handleTobaccoUseChange = (e) => {
    if (reg.test(e.target.value) || (e.target.value) === '') {
      setTobaccoUse(e.target.value);
    }
  }

  useEffect(() => {
    console.log(
      [
        tobacco,
        tobaccoUse,
        tobaccoQuit,
        substancesYN,
        substances,
        substancesFrequency,
        substancesQuit,
        hearing,
        hearingPartial,
        hearingAid,
        vision,
        visionPartial,
        visionAid,
        visionColor,
      ],
    );
    setQuestionsData(
      {
        tobacco,
        tobaccoUse,
        tobaccoQuit,
        substancesYN,
        substances: substances.length === 0 ? null : substances,
        substancesFrequency,
        substancesQuit,
        hearing,
        hearingPartial,
        hearingAid,
        vision,
        visionPartial,
        visionAid,
        visionColor,
      },
    );
  },
  [
    tobacco,
    tobaccoUse,
    tobaccoQuit,
    substancesYN,
    substances,
    substancesFrequency,
    substancesQuit,
    hearing,
    hearingPartial,
    hearingAid,
    vision,
    visionPartial,
    visionAid,
    visionColor,
  ]);
  const [localState, setLocalState] = useLocalStorage('localState', state);
  useEffect(() => {
    if (JSON.stringify(state.introQuestions) !== '{}') setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);

  useEffect(() => {
    if (JSON.stringify(state.introQuestions) === '{}') {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);
  useEffect(() => {
    if (localState.patientData === null) {
      navigate('/404');
    }
  }, []);

  return (
    <div className="introduction__container">
      <Title className="pro-health-or-addison__title" level={2}>
          General Health
      </Title>
      <Divider />

      <h3 className="question">Do you smoke tobacco?</h3>
      <Radio.Group
        onChange={e => setTobacco(e.target.value)}
        className="general-health__radio-group"
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
        {tobacco === true
          ? (
            <div>
              <h3 className="question">How many cigarettes a day do you smoke?</h3>
              <Input
                onChange={handleTobaccoUseChange}
                placeholder="cigarettes/day"
                maxLength={4}
                value={tobaccoUse}
              />

              <h3 className="question">Do you have any interest in quitting smoking?</h3>
              <Radio.Group
                onChange={e => setTobaccoQuit(e.target.value)}
                className="general-health__radio-group"
              >
                <Radio value>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </div>
          )
          : null
          }
      </Radio.Group>

      <h3 className="question">Do you use controlled substance products?</h3>
      <Radio.Group
        onChange={e => setSubstancesYN(e.target.value)}
        className="general-health__radio-group"
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
        {substancesYN === true
          ? (
            <Fade>
            <div>
              <div>
                <h3 className="question">Which substances do you use?</h3>
                {substances.map((something, idx) => (
                  <div
                    key={`item${idx}`}
                    className="general-health-container"
                  >
                    <Input
                      placeholder={`Substance #${idx + 1}`}
                      maxLength={40}
                      onChange={(e) => {
                        setSubstances([
                          ...substances.slice(0, idx),
                          e.target.value,
                          ...substances.slice(idx, substances.length - 1),
                        ]);
                      }}
                    />
                    <Button
                      className="general-health-side-button"
                      type="danger"
                      ghost
                      shape="round"
                      value={idx}
                      onClick={(e) => {
                        e.persist();
                        setSubstances(
                          [...substances.filter((value, idx) => {
                            console.log(`${idx} !== ${e.target.value}`);
                            //inequality must remain != for full functionality
                            //types don't match. don't change to !==
                            return idx != e.target.value;
                          })],
                        );
                      }}
                    >
                      <Icon type="minus" />
                    </Button>

                  </div>
                ))}
                <Button
                  className="general-health-center-button"
                  type="primary"
                  shape="round"
                  onClick={(e) => {
                    setSubstances(
                      [...substances, e.target.value],
                    );
                  }}
                >
                  <Icon type="plus" />
            Add Substance
                </Button>
              </div>

              <h3 className="question">Do you have any interest in learning more about substance abuse?</h3>
              <Radio.Group
                onChange={e => setSubstancesQuit(e.target.value)}
                className="general-health__radio-group"
              >
                <Radio value>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>

              <h3 className="question">How frequently do you use these substance(s) combined?</h3>
              <Radio.Group
                onChange={e => setSubstancesFrequency(e.target.value)}
                className="general_health__input"
              >
                <Radio value="daily">Daily</Radio>
                <Radio value="weekly">Weekly</Radio>
                <Radio value="biweekly">Bi-weekly</Radio>
                <Radio value="monthly">Monthly</Radio>
                <Radio value="lessMonthly">Less than once a month</Radio>
              </Radio.Group>
            </div>
            </Fade>
          )
          : null && setSubstances(null)
          }
      </Radio.Group>
      <Divider />

      <Title className="pro-health-or-addison__title" level={2}>
          Hearing & Vision
      </Title>
      <Divider />

      <h3 className="question">Do you have a hearing impairment?</h3>
      <Radio.Group
        onChange={e => setHearing(e.target.value)}
        className="general-health__radio-group"
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
        {hearing === true
          ? (
            <div className="general-health__question-group">
              <h3 className="question">Is your hearing impairment Full or Partial?</h3>
              <Radio.Group
                onChange={e => setHearingPartial(e.target.value)}
                className="general-health__radio-group"
              >
                <Radio value="Full">Full</Radio>
                <Radio value="Partial">Partial</Radio>
              </Radio.Group>

              <h3 className="question">Are you using any form of hearing aids?</h3>
              <Radio.Group
                onChange={e => setHearingAid(e.target.value)}
                className="general-health__radio-group"
              >
                <Radio value>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </div>
          )
          : null
            }
      </Radio.Group>
      <h3 className="question">Do you have a vision impairment?</h3>
      <Radio.Group
        onChange={e => setVision(e.target.value)}
        className="general-health__radio-group"
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
        {vision === true
          ? (
            <div className="general-health__question-group">
              <h3 className="question">Is your vision impairment Full or Partial?</h3>
              <Radio.Group
                onChange={e => setVisionPartial(e.target.value)}
                className="general-health__radio-group"
              >
                <Radio value="Full">Full</Radio>
                <Radio value="Partial">Partial</Radio>
              </Radio.Group>

              <h3 className="question">Are you using any form of vision aids?</h3>
              <Radio.Group
                onChange={e => setVisionAid(e.target.value)}
                className="general-health__radio-group"
              >
                <Radio value>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>

              <h3 className="question">Is your ability to see color affected?</h3>
              <Radio.Group
                onChange={e => setVisionColor(e.target.value)}
                className="general-health__radio-group"
              >
                <Radio value>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </div>
          )
          : null
            }
      </Radio.Group>
      <Divider />
    </div>
  );
};

export default GeneralHealth;
