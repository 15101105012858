
import _ from 'lodash';
import axios from 'axios';

const postThresholds = (state) => {
  const { thresholds } = state;
  let thresholdData = _.map(thresholds, (vitalValue, vitalName) => {
    if (vitalValue.min) {
      return ({
        minimum: vitalValue.min,
        maximum: vitalValue.max,
        localOwner: state.loggedInDealerID,
        orbId: 'Empty',
        thresholdCarePlanId: 'Empty',
        vitalName: _.capitalize(vitalName),
        xmitId: state.xmitID,
        createdAt: new Date().toJSON().toString(),
      });
    }
  });
  if (thresholds.bloodPressure.systolic.min) {
    thresholdData.push(
      {
        minimum: thresholds.bloodPressure.systolic.min,
        maximum: thresholds.bloodPressure.systolic.max,
        localOwner: state.loggedInDealerID,
        orbId: 'Empty',
        thresholdCarePlanId: 'Empty',
        vitalName: 'Systolic',
        xmitId: state.xmitID,
        createdAt: new Date().toJSON().toString(),
      },
    );
  }
  if (thresholds.bloodPressure.diastolic.min) {
    thresholdData.push(
      {
        minimum: thresholds.bloodPressure.diastolic.min,
        maximum: thresholds.bloodPressure.diastolic.max,
        localOwner: state.loggedInDealerID,
        orbId: 'Empty',
        thresholdCarePlanId: 'Empty',
        vitalName: 'Diastolic',
        xmitId: state.xmitID,
        createdAt: new Date().toJSON().toString(),
      },
    );
  }
  if (thresholds.oxygen.bpm.min) {
    thresholdData.push({
      minimum: thresholds.oxygen.bpm.min,
      maximum: thresholds.oxygen.bpm.max,
      localOwner: state.loggedInDealerID,
      orbId: 'Empty',
      thresholdCarePlanId: 'Empty',
      vitalName: 'BPM',
      xmitId: state.xmitID,
      createdAt: new Date().toJSON().toString(),
    });
  }
  if (thresholds.oxygen.oxygen.min) {
    thresholdData.push(
      {
        minimum: thresholds.oxygen.oxygen.min,
        maximum: thresholds.oxygen.oxygen.max,
        localOwner: state.loggedInDealerID,
        orbId: 'Empty',
        thresholdCarePlanId: 'Empty',
        vitalName: 'Oxygen',
        xmitId: state.xmitID,
        createdAt: new Date().toJSON().toString(),
      },
    );
  }
  thresholdData = _.filter(thresholdData, thresholdValue => thresholdValue != null);
  /* eslint-disable */
  const apiCall = async thresholdData => await Promise.all(thresholdData.map(async item =>
    await axios.post('https://bvaotch7t9.execute-api.us-east-1.amazonaws.com/prod', {
        ...item,
    }))
    );
 /* eslint-enable */
  return (
    apiCall(thresholdData)
  );
};
export default postThresholds;
