import _ from 'lodash';

export const filterAllergies = allergies => {
    const newAllergies = _.map(allergies, allergy => {
        let newAllergy = allergy;
        if (allergy.allergyCode === '') {
            newAllergy = {
                ...newAllergy,
                allergyCode: null,
            };
        }
        if (allergy.allergyDescription === '') {
            newAllergy = {
                ...newAllergy,
                allergyDescription: null,
            };
        }
        if (allergy.comment === '') {
            newAllergy = {
                ...newAllergy,
                comment: null,
            };
        }

        return {
            ...(newAllergy.allergyCode && {
                allergyCode: newAllergy.allergyCode,
            }),
            ...(newAllergy.allergyDescription && {
                allergyDescription: newAllergy.allergyDescription,
            }),
            ...(newAllergy.comment && {
                comment: newAllergy.comment,
            }),
        };
    });
    return _.filter(newAllergies, value => JSON.stringify(value) !== '{}');
};

export const filterMedicalConditions = medicalCondition => {
    const newCondition = _.map(medicalCondition, condition => {
        let newMCondition = condition;
        if (condition.conditionCode === '') {
            newMCondition = {
                ...newMCondition,
                conditionCode: null,
            };
        }
        if (condition.conditionDescription === '') {
            newMCondition = {
                ...newMCondition,
                conditionDescription: null,
            };
        }
        if (condition.comment === '') {
            newMCondition = {
                ...newMCondition,
                comment: null,
            };
        }

        return {
            ...(newMCondition.conditionCode && {
                conditionCode: newMCondition.conditionCode,
            }),
            ...(newMCondition.conditionDescription && {
                conditionDescription: newMCondition.conditionDescription,
            }),
            ...(newMCondition.comment && {
                comment: newMCondition.comment,
            }),
        };
    });
    return _.filter(newCondition, value => JSON.stringify(value) !== '{}');
};

export const filterSurgeries = surgeryArray => {
    const newSurgeryArray = _.map(surgeryArray, condition => {
        let newSurgery = condition;
        if (condition.date === '') {
            newSurgery = {
                ...newSurgery,
                date: null,
            };
        }
        if (condition.procedure === '') {
            newSurgery = {
                ...newSurgery,
                procedure: null,
            };
        }
        if (condition.comment === '') {
            newSurgery = {
                ...newSurgery,
                comment: null,
            };
        }

        return {
            ...(newSurgery.date && {
                date: newSurgery.date,
            }),
            ...(newSurgery.procedure && {
                procedure: newSurgery.procedure,
            }),
            ...(newSurgery.comment && {
                comment: newSurgery.comment,
            }),
        };
    });
    return _.filter(newSurgeryArray, value => JSON.stringify(value) !== '{}');
};

export const filterMedications = medications => {
    const newMedications = _.map(medications, med => {
        let newMed = med;
        if (med.time) {
            if (med.time.includes('')) {
                newMed = {
                    ...newMed,
                    time: med.time.filter(Boolean),
                };
            }
        }
        if (med.name === '') {
            newMed = {
                ...newMed,
                name: null,
            };
        }
        if (med.dosageAmount === '') {
            newMed = {
                ...newMed,
                dosageAmount: null,
            };
        }
        if (med.route === '') {
            newMed = {
                ...newMed,
                route: null,
            };
        }

        return {
            ...(newMed.name && {
                name: newMed.name,
            }),
            ...(newMed.dosageAmount && {
                dosageAmount: newMed.dosageAmount,
            }),
            ...(newMed.route && {
                route: newMed.route,
            }),
            ...(newMed.time.length > 0 && {
                time: newMed.time,
            }),
            ...(med.dosageUnit &&
                newMed.dosageAmount && {
                    dosageUnit: med.dosageUnit,
                }),
        };
    });
    return _.filter(newMedications, value => JSON.stringify(value) !== '{}');
};

export const filterVitalReminders = vitalReminder => {
    const newVitals = _.map(vitalReminder, value => {
        let newVital = value;
        if (value.time) {
            if (value.time.includes('')) {
                newVital = {
                    ...newVital,
                    time: value.time.filter(Boolean),
                };
            }
        }
        if (value.vitalType === '') {
            newVital = {
                ...newVital,
                name: null,
            };
        }
        if (value.day) {
            if (value.day.includes('')) {
                newVital = {
                    ...newVital,
                    day: value.day.filter(Boolean),
                };
            }
        }
        return {
            ...(newVital.time.length > 0 && {
                time: newVital.time,
            }),
            ...(newVital.vitalType && {
                vitalType: newVital.vitalType,
            }),
            ...(newVital.day.length > 0 && {
                day: newVital.day,
            }),
        };
    });
    return _.filter(newVitals, value => JSON.stringify(value) !== '{}');
};