import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import CreateAddisonUser from '../components/CreateAddisonUser/CreateAddisonUser';

const CreateAddisonUserPage = ({ state = {}, dispatch, path }) => {
  return (
    <Layout
      state={state}
      previousPath="/home-wellbeing"
      nextPath=""
      dispatch={dispatch}
      introduction
    >
      <div className="app__content-box">
        <CreateAddisonUser state={state} dispatch={dispatch} path={path} />
      </div>
    </Layout>
  );
};

export default CreateAddisonUserPage;
