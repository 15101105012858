import React, { useState, useEffect } from 'react';
import {
  Typography, Input, Radio, Divider,
} from 'antd';
import _ from 'lodash';
import { navigate } from '@reach/router';
import cuid from 'cuid';
import { useLocalStorage } from 'standard-hooks';

const { Title } = Typography;

/**
 * @desc Confirm responsible parties and provide caregiver contact information.
 * @author Jacob Wright <jwright@ecg-hq.com>
 * @prop {Object}  state - global state of patient data
 * @prop {function} setQuestionsData - Create an object of emergency and caregiver contact information
 */


const EmergencyContact = ({ setQuestionsData, state, dispatch }) => {
  const [emergencyContactConfirm, setEmergencyContactConfirm] = useState(null);
  const [caregiver, setCaregiver] = useState(null);
  const [caregiverName, setCaregiverName] = useState(null);
  const [caregiverFamily, setCaregiverFamily] = useState(null);
  const [caregiverNumber, setCaregiverNumber] = useState(null);
  const reg = /^\d+$/;
  const regC = /^[A-Za-z\s]+$/;

  const handleCaregiverNameChange = (e) => {
    if (regC.test(e.target.value) || (e.target.value) === '' || (e.target.value) === ' ') {
      setCaregiverName(e.target.value);
    }
  };

  const handleCaregiverNumberChange = (e) => {
    if (reg.test(e.target.value) || (e.target.value) === '') {
      setCaregiverNumber(e.target.value);
    }
  };

  useEffect(() => {
    console.log(
      [
        emergencyContactConfirm,
        caregiver,
        caregiverName,
        caregiverFamily,
        caregiverNumber,
      ],
    );
    setQuestionsData(
      {
        emergencyContactConfirm,
        caregiver,
        caregiverName,
        caregiverFamily,
        caregiverNumber,
      },
    );
  },
  [
    emergencyContactConfirm,
    caregiver,
    caregiverName,
    caregiverFamily,
    caregiverNumber,
  ]);


  const [localState, setLocalState] = useLocalStorage('localState', state);

  useEffect(() => {
    if (JSON.stringify(state.introQuestions) !== '{}') setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);

  useEffect(() => {
    if (JSON.stringify(state.introQuestions) === '{}') {
      console.log('State before dispatch: ', localState);

      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);
  useEffect(() => {
    if (localState.patientData === null) {
      navigate('/404');
    }
  }, []);

  return (
    <div className="introduction__container"  >
      <Title className="pro-health-or-addison__title" level={2}>
          Emergency Contacts
      </Title>
      <Divider />

      {(state.patientData && state.patientData.data.contacts.responsibleparty1) && <ResponsiblePartyCard data = {state.patientData.data.contacts.responsibleparty1} num={1}/>}
      {(state.patientData && state.patientData.data.contacts.responsibleparty2) && <ResponsiblePartyCard data = {state.patientData.data.contacts.responsibleparty2} num={2}/>}
      {(state.patientData && state.patientData.data.contacts.responsibleparty3) && <ResponsiblePartyCard data = {state.patientData.data.contacts.responsibleparty3} num={3}/>}
      {(state.patientData && state.patientData.data.contacts.responsibleparty4) && <ResponsiblePartyCard data = {state.patientData.data.contacts.responsibleparty4} num={4}/>}

      <h3 className="question">Is the emergency contact information correct?</h3>
      <Radio.Group
        onChange={e => setEmergencyContactConfirm(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
      <Divider />

      <Title className="pro-health-or-addison__title" level={2}>
          Caregiver Info
      </Title>
      <Divider />

      <h3 className="question">Do you have a caregiver?</h3>
      <Radio.Group
        onChange={e => setCaregiver(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
        {caregiver === true
          ? (
            <div>
              <h3 className="question">What is the caregiver's name?</h3>
              <Input
                onChange={handleCaregiverNameChange}
                maxLength={70}
                placeholder="First Name Last Name"
                value={caregiverName}
              />

              <h3 className="question">Is the caregiver a member of your family?</h3>
              <Radio.Group
                onChange={e => setCaregiverFamily(e.target.value)}
              >
                <Radio value>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>

              <h3 className="question">What is the caregiver's phone number?</h3>
              <Input
                onChange={handleCaregiverNumberChange}
                placeholder="(xxx)-xxx-xxxx"
                maxLength={10}
                value={caregiverNumber}
              />
            </div>
          )
          : null
        }
        <br />
      </Radio.Group>
      <Divider />
    </div>
  );
};

export const ResponsiblePartyCard = ({ data, num }) => (
  <div
    data-testid="responsible-party-card" 
    key={cuid()}
  >
    <h3>{`Emergency Contact ${num}`}</h3>
    <div>{`Contact Name: ${data.rpname}`}</div>
    <div>{`Contact Phone Number: ${data.rpphone}`}</div>
    <div>{`Relationship: ${data.rprelationship}`}</div>
    <br />
  </div>
);


export default EmergencyContact;
