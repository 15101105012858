import React, { useState, useEffect } from 'react';
import {
  Button, TimePicker as TimePickerAntD, Icon,
} from 'antd';
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { TimePicker as Time, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';

/**
* @desc time picker element used in the Medications page and manages the time state separately
* @author Dakota Marquez <dmarquez@ecg-hq.com>
* @prop {number} timeIndex - index position within the time array inside the medications array
* @prop {array} medications - contains name, dosage, route, and reminder times for each medication
* @prop {function} setMedications - adds med data to the medications array every time new data is entered
* @prop {number} medicationsIndex - index position within the medications array
*/

const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#1882AF',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#1882AF',
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#1882AF',
      },
      thumb: {
        border: '14px solid #1882AF',
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: '#1882AF',
      },
    },
    MuiButton: {
      textPrimary: {
        color: '#010101',
      },
    },
  },
});
const TimePicker = ({
  timeIndex, medications, setMedications, medicationsIndex,
}) => {
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState(null);
  
  /**
 * @desc TimePicker useEffect - the time array is an object inside of the medications object
 * @author Dakota Marquez <dmarquez@ecg-hq.com>
 * @returns either a formatted time or null
 */

  useEffect(() => {
    setTime(medications[medicationsIndex].time[timeIndex] ? moment(medications[medicationsIndex].time[timeIndex], 'h:mm a') : null);
  },
  [medications[medicationsIndex].time[timeIndex]]);
  const [selectedData, handleDataChange] = useState(new Date(1996, 3, 1, 0, 0, 0));
  return (
    <div className="timePicker">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={materialTheme}>
          <Time
            value={selectedData}
            onChange={handleDataChange}
            onAccept={(timeString) => {
              const newTime = moment(timeString).format('h:mm a');
              setMedications([
                ...medications.slice(0, medicationsIndex),
                {
                  ...medications[medicationsIndex],
                  time: [...Object.assign(medications[medicationsIndex].time, { [timeIndex]: newTime })],
                },
                ...medications.slice(medicationsIndex + 1, medications.length),
              ]);
            }}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
      <Button
        className="side-input-button"
        type="danger"
        ghost
        shape="round"
        size="small"
        onClick={(e) => {
          e.persist();
          setMedications([
            ...medications.slice(0, medicationsIndex),
            {
              ...medications[medicationsIndex],
              // inequality must remain != for full functionality
              // types don't match. don't change to !==
              time: [...medications[medicationsIndex].time.filter((value, idx) => timeIndex != idx)],
            },
            ...medications.slice(medicationsIndex + 1, medications.length),
          ]);
        }}
      >
        <Icon type="minus" />
      </Button>
      <br />
      <br />
    </div>
  );
};

export default TimePicker;
