import React, { useState } from 'react';
import {
  Input, Button, message, InputNumber, Typography,
} from 'antd';

const { Title } = Typography;
const InputGroup = Input.Group;
const InputGroupTwo = Input.Group;

/**
* @desc pulse oximeter tutorial and first reading in the Activation section
* @author Hector Rodriguez <hrodriguez@ecg-hq.com>
* @prop {function} dispatch - a reducer of state object
* @prop {string} vitalType - name of primary vital metric
* @prop {string} secondVitalType - name of secondary vital metric
* @prop {string} vitalName  - indicates which vital threshold is being set
*/

const Threshold = ({
  vitalType, secondVitalType, dispatch, vitalName, hidden
}) => {
  const [thresholdView, setThresholdView] = useState(false);
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [secondMinValue, setSecondMinValue] = useState('');
  const [secondMaxValue, setSecondMaxValue] = useState('');
  const reg = /^(\d*\.)?\d+$/;

  const DataToPretty = {
    systolic: 'Systolic',
    diastolic: 'Diastolic',
    oxygen: 'Oxygen',
    bpm: 'Heart rate',
    thermometer: 'Temperature',
    glucose: 'Glucose',
    weight: 'Weight',
  };
  const takeThreshold = () => {
    setThresholdView(true);
  };

  const setMin = (e) => {
    e.persist();
    setMinValue(e.target.value);
  };

  const setMax = (e) => {
    e.persist();
    setMaxValue(e.target.value);
  };

  const setSecondMin = (e) => {
    e.persist();
    setSecondMinValue(e.target.value);
  };

  const setSecondMax = (e) => {
    e.persist();
    setSecondMaxValue(e.target.value);
  };

  /**
 * @desc Checks if threshold values entered fulfill certain criteria using regularExpressions
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @returns dispatch - To global state for appropriate vital threshold
 */

  const checkValues = () => {
    if ((reg.test(minValue) && reg.test(maxValue))
      && (parseFloat(minValue, 10) < parseFloat(maxValue, 10))
      && !secondVitalType) {
      console.log(`${vitalType} threshold has been recorded.`);
      dispatch({ type: `UPDATE_${vitalName}_THRESHOLD`, payload: { min: minValue, max: maxValue } });
      message.success('Threshold submitted');
      setThresholdView(false);
      return;
    }

    if ((reg.test(secondMinValue) && reg.test(secondMaxValue))
     && (parseFloat(secondMinValue, 10) < parseFloat(secondMaxValue, 10))
     && secondVitalType) {
      console.log(`${secondVitalType} exists`);
      dispatch({
        type: `UPDATE_${vitalName}_THRESHOLD`,
        // eslint-disable-next-line max-len
        payload: { [vitalType]: { min: minValue, max: maxValue }, [secondVitalType]: { min: secondMinValue, max: secondMaxValue } },
      });
      message.success('Threshold submitted');
      setThresholdView(false);
      return;
    }
    message.error('Incorrect values');
    setMinValue('');
    setMaxValue('');
    setSecondMinValue('');
    setSecondMaxValue('');
    console.log(`Min and max: ${minValue} ${maxValue}`);
  };
  // if statement uses conditional rendering, so that page isn't bogged down with extra content until it is used/needed.
  // if statements are also easier to write unit tests for rather than ternary statements.
  if (!hidden) {
    return (
    <div data-testid="thresholdComponent" className="activation-tutorials__container">
        <>
          <br />
          <Title level={4}>
            Custom Thresholds
          </Title>
          <div className="activation-tutorials__warning-text">
          *If you are not sure what your threshold should be,
            <br />
            {' '}
            talk to your physician.
          </div>
          {secondVitalType ? <div className="activation-tutorials__titles"> {DataToPretty[vitalType]} </div> : null }
          <InputGroup className="activation-tutorials__threshold-input-one">
            <Input
              style={{ width: 300 }}
              maxLength={5}
              placeholder={`Enter min value for ${DataToPretty[vitalType]}${vitalType === 'weight' ? '(lbs.)' : ''}`}
              value={minValue}
              onChange={setMin}
            />
            <br />
            <Input
              style={{ width: 300 }}
              maxLength={5}
              placeholder={`Enter max value for ${DataToPretty[vitalType]}${vitalType === 'weight' ? '(lbs.)' : ''}`}
              value={maxValue}
              onChange={setMax}
            />
          </InputGroup>
          <Button
            hidden={secondVitalType}
            onClick={checkValues}
            type="primary"
            shape="round"
            size="large"
          >
                    Submit
          </Button>
        </>
      {secondVitalType 
      &&
        (
          <>
            <div className="activation-tutorials__titles">{DataToPretty[secondVitalType]}</div>
            <InputGroupTwo className="activation-tutorials__threshold-input-two">
              <Input
                style={{ width: 300 }}
                maxLength={3}
                placeholder={`Enter min value for ${secondVitalType}`}
                value={secondMinValue}
                onChange={setSecondMin}
              />
              <br />
              <Input
                style={{ width: 300 }}
                maxLength={3}
                placeholder={`Enter max value for ${secondVitalType}`}
                value={secondMaxValue}
                onChange={setSecondMax}
              />
            </InputGroupTwo>
            <Button
              onClick={checkValues}
              type="primary"
              shape="round"
              size="large"
            >
                  Submit
            </Button>
          </>
        )
      }
    </div>
  );}
  return(
    <div data-testid="emptyComponent" ></div>
  )
  
};

export default Threshold;
