import Fade from 'react-reveal/Fade';
import React, { useEffect } from 'react';
import { Layout as AntLayout, Button, Typography, Modal, Icon, Menu, Dropdown } from 'antd';
import { navigate } from '@reach/router';
import { Auth } from 'aws-amplify';
import logo from './ecg_favicon_32x.png';
import {
  filterAllergies,
  filterMedicalConditions,
  filterSurgeries,
  filterVitalReminders,
  filterMedications,
} from '../../functions/filterObjects';
import postReminders from '../../API/postReminders';
import postMedicalOverview from '../../API/postOverview';

export const getProgressColor = status => {
  switch (status) {
    case 'in progress':
      return 'blue';
    case 'complete':
      return 'green';
    case 'in queue':
      return 'grey';
    case 'error':
      return 'red';
    default:
      return 'grey';
  }
};
const goBack = () => window.history.back();
const { Text } = Typography;
const { confirm } = Modal;

/**
 * @desc sets the overall Layout of every page that other components are nested in
 * @author Michael Lyon <mlyon@ecg-hq.com>
 * @prop {Object}  state - global state of patient data
 * @prop {function} dispatch - a reducer of state object
 * @prop {JSX} children - a default prop that gets passed to every component
 * @prop {string} nextPath - next routing path
 * @prop {string} previousPath - previous routing path
 * @prop {Object} questionsData - an object of introduction survey question answers
 * @prop {boolean} back - determines whether the user can go to the previous page in their history or the previous path in the app
 * @prop {boolean} introduction - determines whether the user is in the introduction questions section or not
 * @prop {array} medicationsData - an array of objects that stores all data regarding medications and their schedules
 */
const Layout = ({
  children, // inner html of component
  state = {}, // global state
  dispatch, // set global state
  nextPath,
  previousPath,
  back,
  introduction,
  questionsData,
  reminders,
  overview,
}) => {
  const menu = (
    <Menu
      onClick={() => {
        Auth.signOut();
        window.location.reload(true);
      }}
    >
      <Menu.Item key="1">
        <Icon type="logout" />
        Logout
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <AntLayout className="layout__layout">
      <AntLayout.Header className="layout__header">
        <a href="/">
          <div className="layout__logo">
            <img
              style={{
                height: '15%',
                width: '15%',
                paddingRight: '5%',
              }}
              src={logo}
              alt="Logo"
              href="/"
            />
            <Typography.Title level={2} className="layout__logo-text" strong>
              Patient Intake
            </Typography.Title>
          </div>
        </a>
        <Dropdown
          className="layout__logged-in-text"
          overlay={menu}
          placement="bottomCenter"
          trigger={['hover']}
        >
          <Button ghost>
            {state.loggedInDealerID ? state.loggedInDealerID : ''} <Icon type="down" />
          </Button>
        </Dropdown>
      </AntLayout.Header>

      <AntLayout.Content className="layout__content">
        <div className="layout__content-box">
          <Fade>{children}</Fade>
        </div>
        <div className="layout__navigation-buttons">
          {/* Previous Button */}
          <Button
            disabled={!previousPath}
            size="large"
            type="primary"
            onClick={() => {
              if (introduction) {
                confirm({
                  title: 'Are you sure you want to go to previous page?',
                  content: 'All data entered from the current and previous page will be lost.',
                  okText: 'Yes',
                  okType: 'danger',
                  cancelText: 'No',
                  onOk() {
                    navigate(previousPath);
                  },
                  onCancel() {},
                });
              }
              if (!introduction) {
                if (back) {
                  goBack();
                } else {
                  navigate(previousPath);
                }
              }
            }}
          >
            Previous
          </Button>

          {/* Next Button */}
          <Button
            disabled={!nextPath}
            type="primary"
            size="large"
            /**
             * @desc Next Button onClick -
             * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
             * @returns
             */

            onClick={() => {
              if (overview) {
                dispatch({
                  type: 'UPDATE_MEDICAL_CONDITION',
                  payload: filterMedicalConditions(overview.medicalCondition),
                });
                dispatch({
                  type: 'UPDATE_ALLERGIES',
                  payload: filterAllergies(overview.allergies),
                });
                dispatch({
                  type: 'UPDATE_SURGERIES',
                  payload: filterSurgeries(overview.surgeries),
                });
                dispatch({
                  type: 'UPDATE_HEIGHTFEET',
                  payload: overview.heightFeet,
                });
                dispatch({
                  type: 'UPDATE_HEIGHTINCHES',
                  payload: overview.heightInches,
                });                
                dispatch({
                  type: 'UPDATE_WEIGHT',
                  payload: overview.weight,
                });
                dispatch({
                  type: 'UPDATE_BLOODTYPE',
                  payload: overview.bloodType,
                });
                dispatch({
                  type: 'UPDATE_BLOODTYPE_RH',
                  payload: overview.bloodTypeRH,
                });                
                console.log("Medical Overview Check", overview);
                console.log("State after medical overview update", state);
                postMedicalOverview(state.patientData, overview);
              }
              // dispatch({ type: 'UPDATE_STEP_PROGRESS', payload: pageNumber });
              if (introduction && !reminders) {
                console.log('Questions from page', questionsData);
                dispatch({
                  type: 'UPDATE_INTRO_QUESTIONS',
                  payload: questionsData,
                });
                navigate(nextPath);
              }
              if (introduction && reminders) {
                const newMedReminder = filterMedications(reminders.medReminder);
                const newVitalReminder = filterVitalReminders(reminders.vitalReminder);
                console.log("This is the state", state);
                const postMedRem = {
                  patientData: { id: state.patientData.id },
                  reminders: { medReminder: newMedReminder },
                };
                console.log(postMedRem);
                const newReminder = {
                  medReminder: newMedReminder,
                  vitalReminder: newVitalReminder,
                };
                console.log('Questions from page', questionsData);
                console.log('Medications from page', newMedReminder);
                dispatch({
                  type: 'UPDATE_INTRO_QUESTIONS',
                  payload: questionsData,
                });
                dispatch({
                  type: 'UPDATE_REMINDERS',
                  payload: newReminder,
                });
                navigate(nextPath);
                postReminders(postMedRem);
              } else {
                console.log("Else Next Button On Click");
                navigate(nextPath);
              }
            }}
          >
            Next
          </Button>
        </div>
      </AntLayout.Content>
    </AntLayout>
  );
};
export default Layout;
