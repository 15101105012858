import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import Thermometer from '../components/Thermometer/Thermometer';

const ThermometerPage = ({ state = {}, dispatch }) => {
  const [path, setPath] = useState('');
  return (
    <Layout
      state={state}
      previousPath="/vitals"
      dispatch={dispatch}
      nextPath={path}
    >
      <div className="app__content-box">
        <Thermometer state={state} dispatch={dispatch} setPath={setPath} />
      </div>
    </Layout>
  );
};
export default ThermometerPage;
