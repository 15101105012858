import React from 'react';
import { Button, Typography } from 'antd';
import { navigate } from '@reach/router';

const { Title } = Typography;

/**
 * @desc user indicates whether they are provisioning an Addison system or not
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @prop {function} dispatch - a reducer of state object
 */

const ProHealthorAddison = ({ dispatch }) => {
  localStorage.removeItem('localState');
  return (
    <div className="pro-health-or-addison__container">
      <Title className="pro-health-or-addison__title" level={2}>
        Which device are you activating today?
      </Title>
      <Button
        className="pro-health__button"
        type="primary"
        size="large"
        onClick={() => {
          dispatch({ type: 'UPDATE_PRO_HEALTH_ONLY', payload: true });
          navigate('activation-search');
        }}
      >
        Pro Health
      </Button>
      <Button
        className="addison__button"
        type="primary"
        size="large"
        onClick={() => {
          dispatch({ type: 'UPDATE_PRO_HEALTH_ONLY', payload: false });
          navigate('activation-search');
        }}
      >
        Pro Health With Addison
      </Button>
    </div>
  );
};

export default ProHealthorAddison;
