/* eslint-disable */

import React from 'react';
import ReactDOM from 'react-dom';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import getKey from './API/getKey';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import 'antd/dist/antd.css';
import './styles.scss';

const LogRocket = require('logrocket/setup')({
  sdkServer: 'https://logrocket.ecg-addison.com',
  ingestServer: 'https://logrocket.ecg-addison.com'
});
LogRocket.init('lifesupportmedical/qa');

Amplify.configure({
  ...awsconfig,
  Auth: {
    identityPoolId: 'us-east-1:ef4b86c2-e238-4fbe-a138-dd7370710cec',
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: 'us-east-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-1_oXba81rHO',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '62lvfm64jvtve0pn8neeb9qsdp',
  },
  
    graphql_endpoint: 'https://ahligu7qhrggti3ynofjojovee.appsync-api.us-east-1.amazonaws.com/graphql',
    graphql_headers: async () =>{
      const cognitoUserSession = await Auth.currentSession();
      const { idToken: { jwtToken }} = cognitoUserSession;
      const key = await getKey(jwtToken);
      return({
      'Authorization': key
      })
    }
});

ReactDOM.render(
  <App />, 
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
