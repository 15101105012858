import React, {
  useState,
  useEffect,
} from 'react';
import {
  Typography, Button, Spin,
} from 'antd';
import ReactPlayer from 'react-player';
import _ from 'lodash';
import Fade from 'react-reveal/Fade';
import { API, graphqlOperation } from 'aws-amplify';
import { useLocalStorage } from 'standard-hooks';
import vitalsPath from '../../functions/vitalsPath';
import formatVitalsData from '../../API/formatVitalsData';
import subscription from '../../API/graphql/subscribeToAneltoEvents';
import Threshold from '../Threshold/Threshold';

const { Title } = Typography;

/**
* @desc pulse oximeter tutorial and first reading in the Activation section
* @author Dakota Marquez <dmarquez@ecg-hq.com>
* @author Hector Rodriguez <hrodriguez@ecg-hq.com>
* @prop {Object} state - global state of patient data
* @prop {function} dispatch - a reducer of state object
* @prop {function} setPath - defines url path
*/

const PulseOx = ({ setPath, state = {}, dispatch }) => {
  const defaultState = { Oxygen: null, BPM: null };
  const [reading, setReading] = useState(defaultState);

  // the states of what content is being rendered
  const [viewThreshold, setViewThreshold] = useState(true);
  const [readingView, setReadingView] = useState(true);
  
  /**
 * @desc PulseOx useEffect - 
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @returns 
 */

  useEffect(() => {
    const subscribe = API.graphql(graphqlOperation(subscription, { Account: state.xmitID }))
      .subscribe({
        next: (data) => {
          console.log('Vitals Data', formatVitalsData(data));
          setReading(formatVitalsData(data));
        },
      });
    return () => subscribe.unsubscribe();
  }, [state.xmitID]);
  const takeReading = () => {
    if (reading.BPM !== null){
    console.log('pulse oxygen reading successfully taken');
    console.log(`READING: ${reading.PulseOx}`);
    setReadingView(false);
    const remainingReadings = vitalsPath(
      _.filter(
        state.devices, device => device !== 'pulseOx' && device !== 'thermometer' && device !== 'weight' && device !== 'glucose',
      ),
    );
    if (remainingReadings === undefined) {
      setPath('/complete-activation');
    } else {
      setPath(remainingReadings);
    }
  }};
  const resetReading = () => {
    console.log('resetting pulse oxygen reading');
    setReadingView(true);
    setReading(defaultState);
  };
  const [localState, setLocalState] = useLocalStorage('localState', state);
  useEffect(() => {
    if (JSON.stringify(state.patientData) !== 'null') setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);

  useEffect(() => {
    if (JSON.stringify(state.patientData) === 'null') {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);
  useEffect(() => {
    dispatch({
      type: 'UPDATE_OXYGEN_THRESHOLD',
      payload: { oxygen: { min: '92', max: '100' }, bpm: { min: '60', max: '100' } },
    });
  }, []);
  useEffect(() => {
    takeReading();
  }, [reading.BPM]);
  return (
    <div className="activation-tutorials__container">
      <Title level={2}>
            Pulse Oximeter Tutorial
      </Title>
      <div className="responsive-image">
        <ReactPlayer
          url="https://addison-project-anim-files.s3.amazonaws.com/Videos/VitalsTutorials/POTutorial_v03.mp4"
          controls
          height="100%"
          width="100%"
        />
      </div>
      <br />
      <Fade>
        <div>
        <Title level={4} className="activation-tutorials__text">
          Please use your pulse oximeter when you are ready.
          <br />
          Your reading is:
        </Title>
          { (reading.Oxygen === null || reading.BPM === null)
            ? (
              <div className="activation-tutorials__spinner">
                {' '}
                <Spin />
                {' '}
              </div>
            )
            : (
              <div className="activation-tutorials__vital-feedback">
                Pulse Oxygen: {reading.Oxygen}
                <br /> 
                BPM: {reading.BPM}
              </div>
            )}
          <div className="activation-tutorials__text">
            {(reading.Oxygen !== null || reading.BPM !== null)
              ? (
                <>
                  <p>
                    Your thresholds have been set to the recommended standard:
                    <br />
                    60 bpm to 100 bpm
                    <br />
                    95% to 100%
                  </p>
                </>
              )
              : null}
          </div>
          <div hidden={readingView}>
            <Button
              type="link"
              // shows threshold input fields by rendering Threshold.jsx component
              onClick={() => setViewThreshold(false)}
              className="activation-tutorials__threshold-button"
            >
              Set Personal Thresholds
            </Button>
            <div className="activation-tutorials__threshold-reading">
              <Button
                type="primary"
                shape="round"
                size="large"
                onClick={resetReading}
              >
                Retake Reading
              </Button>
            </div>
          </div>
        </div>
      </Fade>
      <br />
      <Threshold vitalType="oxygen" secondVitalType="bpm" dispatch={dispatch} vitalName="OXYGEN" hidden={viewThreshold} />
    </div>
  );
};

export default PulseOx;
