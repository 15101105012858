import React from 'react';
import Help from '../components/Help/Help';
import Layout from '../components/Layout/Layout';

const Default = ({ state = {}, dispatch }) => (
<Layout>
  <div className="app__content-box">
    <Help dispatch={dispatch} />
  </div>
</Layout>

);

export default Default;
