import axios from 'axios';


// get api key to make graphql request, header grants authorization
// eslint-disable-next-line consistent-return
const getKey = async (jwtToken) => {
  const url = 'https://tlbv660014.execute-api.us-east-1.amazonaws.com/dev';
  try {
    const { data: { AccessToken } } = await axios.get(url,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
      });
    return AccessToken;
  } catch (err) {
    console.log('Error: ', err);
  }
};

export default getKey;
