import axios from 'axios';
import _ from 'lodash';

const postMedicalOverview = async (state, overview) => {
  try {
    const body = {
      username: state.id,
      data: {
        weight: overview.weight.weight,
        height: parseFloat(overview.heightFeet.feet * 12) + parseFloat(overview.heightInches.inches),
        bloodType: overview.bloodType.bloodType + overview.bloodTypeRH.bloodTypeRH,
        specialConcerns:{
            medicalConditions: overview.medicalCondition,
            surgeries: overview.surgeries,
            allergies: overview.allergies,
        },
      }
    };
    console.log('body', body);
    const response = await axios.post('https://0jpq8t9xjd.execute-api.us-east-1.amazonaws.com/prod', {
        ...body,
      },
    );
    return response;
  } catch (e) {
    return e;
  }
};
export default postMedicalOverview;