import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import Reminders from '../components/Reminders/Reminders';

const RemindersPage = ({ state, dispatch }) => {
  const [path, setPath] = useState('');
  const [reminders, setReminders] = useState(null);
  return (
    <Layout
      state={state}
      previousPath="/medical-overview"
      nextPath={path}
      dispatch={dispatch}
      introduction
      reminders={reminders}
    >
      <div className="app__content-box">
        <Reminders
          state={state}
          dispatch={dispatch}
          setPath={setPath}
          setReminders={setReminders}
        />
      </div>
    </Layout>
  );
};

export default RemindersPage;
