/* eslint-disable max-len */
import _ from 'lodash';
import {
  API,
  graphqlOperation
} from 'aws-amplify';
import getReminderSchedule from './graphql/getReminderSchedule';
import updateReminderSchedule from './graphql/updateReminderSchedule';

const postReminders = async (state) => {
  // const {
  //   data
  // } = await API.graphql(graphqlOperation(getReminderSchedule, {
  //     id: `${state.patientData.id}-med-reminder-schedule`
  //   }))
  //   .catch(err => console.log(err));
  // console.log('data from get reminder schedule: ', data);
  console.log('Medication data: ', state.reminders);
  if ((state.reminders.medReminder === undefined || state.reminders.medReminder.length === 0) &&
    (state.reminders.vitalReminder === undefined || state.reminders.vitalReminder.length === 0)) return 'No reminders to send.';

  const sendingData = {
    id: `${state.patientData.id}-med-reminder-schedule`,
    reminderScheduleUserId: state.patientData.id,
    owner: state.patientData.id,
    ...state.reminders.medReminder && state.reminders.medReminder.length !== 0 && {
      medicationDetails: [...state.reminders.medReminder]
    },
    ...state.reminders.vitalReminder && state.reminders.vitalReminder.length !== 0 && {
      vitalDetails: [...state.reminders.vitalReminder]
    },
  };
  console.log('data to be sent', JSON.stringify(sendingData));
  const responseFromUpdate = await API.graphql(graphqlOperation(updateReminderSchedule, {
    input: sendingData
  }));
  console.log('response from updating medication', responseFromUpdate);
  return responseFromUpdate;
};


export default postReminders;