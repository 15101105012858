import React, { useState, useEffect } from 'react';
import { Typography, Button, Result, Icon, Spin, Modal } from 'antd';
import { TiPhoneOutline } from 'react-icons/ti';
import _ from 'lodash';
import { useLocalStorage } from 'standard-hooks';
import { navigate } from '@reach/router';
import postRapidActivate from '../../API/postRapidActivate';

const { Title, Paragraph, Text } = Typography;

/**
 * @desc Activating ProHealth system by sending a signal to Rapid Response
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @prop {Object} state - global state of patient data
 * @prop {function} setPath - defines url path
 */

const RapidActivation = ({ setPath, state, dispatch }) => {
  const [activated, setActivated] = useState({
    status: 'warning',
    subTitle: 'Not Activated',
    disabled: false,
  });
  const [loading, setLoading] = useState(false);
  const { confirm } = Modal;

  const confirmSignal = async () => {
    console.log('activating');
    setLoading(true);
    const response = await postRapidActivate(state).catch(err => console.error(err));
    console.log('rapid repsonse api call ', response);
    setTimeout(() => {
      setLoading(false);
      setActivated({ status: 'success', subTitle: 'Activated', disabled: true });
      if (response.status === 200) {
        setActivated({ status: 'success', subTitle: 'Activated', disabled: true });
        setPath('/test-signal');
      } else {
        setActivated({ status: 'error', subTitle: response.toString(), disabled: false });
        setPath('');
      }
    }, 10000);
  };
  const callModal = () => {
    confirm({
      title: 'Customer Service Contact Info',
      content: (
        <div>
          <h3>Contact us</h3>

          <Icon type="home" />
          <h4>Address:</h4>
          <p>506 S. Main Street, Suite 1000</p>
          <p>Las Cruces, NM 88001</p>

          <Icon type="phone" />
          <h4>Phone:</h4>
          <p>(833)-324-5433 ext. 2</p>

          <Icon type="mail" />
          <h4>Email:</h4>
          <p>support@ecg-hq.com</p>
        </div>
      ),
      onOk() {},
    });
  };
  const [localState, setLocalState] = useLocalStorage('localState', {});

  useEffect(() => {
    if (_.isNull(state.patientData)) {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);
  useEffect(() => {
    if (_.isObject(state.patientData)) setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);

  useEffect(() => {
    console.log('State in useEffect: ', localState);
    if (localState.patientData === null) {
      navigate('/404');
    }
  }, []);
  return (
    <div className="rapid-activation__container">
      <Title className="rapid-activation__title" level={2}>
        Rapid Response Activation
      </Title>
      {loading ? (
        <Spin className="rapid-activation__spin" size="large" />
      ) : (
        <Result
          status={activated.status}
          title="Activation Status..."
          subTitle={activated.subTitle}
          extra={[
            <Button
              key={1}
              type="primary"
              shape="round"
              size="large"
              disabled={activated.disabled}
              onClick={confirmSignal}
            >
              Send Activation Signal
            </Button>,
          ]}
        >
          {activated.status === 'error' && (
            <div className="desc">
              <Paragraph>
                <Text
                  strong
                  style={{
                    fontSize: 16,
                  }}
                >
                  Some of the common activation errors are as follows:
                </Text>
              </Paragraph>
              <Paragraph>
                <Icon style={{ color: 'red' }} type="close-circle" /> Your account has been frozen{' '}
                <Button type="link" onClick={callModal}>
                  Contact Customer Service <TiPhoneOutline />
                </Button>
              </Paragraph>
              <Paragraph>
                <Icon style={{ color: 'red' }} type="close-circle" /> Loss of internet connection{' '}
              </Paragraph>
              <Paragraph>
                <Icon style={{ color: 'red' }} type="close-circle" /> Empty list of responsible
                parties{' '}
              </Paragraph>
            </div>
          )}
        </Result>
      )}
    </div>
  );
};

export default RapidActivation;
