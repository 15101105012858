import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import Glucose from '../components/Glucose/Glucose';

const GlucosePage = ({ state = {}, dispatch }) => {
  const [path, setPath] = useState('');
  return (
    <Layout
      state={state}
      previousPath="/vitals"
      dispatch={dispatch}
      nextPath={path}
      back
    >
      <div className="app__content-box">
        <Glucose state={state} dispatch={dispatch} setPath={setPath} />
      </div>
    </Layout>
  );
};
export default GlucosePage;
