const vitalsPath = (state) => {
  const path = state.map((value) => {
    switch (value) {
      case 'glucose':
      case 'weight':
      case 'thermometer':
      case 'pulseOx':
      case 'bloodPressure':
        return `/${value}`;
      default:
        return null;
    }
  });
  return path[0];
};

export default vitalsPath;
