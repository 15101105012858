const state = {
  stepProgress: [
    'in progress',
    'in queue',
    'in queue',
    'in queue',
  ],
  xmitID: '',
  patientData: null,
  proHealthOnly: true,
  devices: [],
  medicalCondition: [],
  allergies: [],
  surgeries: [],
  loggedInDealerID: '',
  introQuestions: {},
  weight: {},
  heightInches: {},
  heightFeet: {},
  reminders: {
    medReminder: [{
      dosageUnit: 'units',
      time: [''],
      dosageAmount: '',
      name: '',
      route: '',
    }, ],
    vitalReminder: [{
      vitalType: '',
      time: [''],
      day: ['']
    }],
    activityReminder: [''],
  },
  thresholds: {
    weight: {
      min: null,
      max: null
    },
    glucose: {
      min: null,
      max: null
    },
    thermometer: {
      min: '95',
      max: '99'
    },
    oxygen: {
      oxygen: {
        min: '92',
        max: '100'
      },
      bpm: {
        min: '60',
        max: '100'
      },
    },
    bloodPressure: {
      systolic: {
        min: '90',
        max: '120'
      },
      diastolic: {
        min: '60',
        max: '80'
      },
    },
  },
};

export default state;