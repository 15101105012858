import _ from 'lodash';

const formatVitalsData = (vitals) => {
  // deconstruct GraphQL query
  let {
    value: {
      data: {
        onCreateAneltoEvents: {
          BPM,
          PulseAvg,
          Systolic,
          Diastolic,
          RawData,
          Thermometer,
          Glucose,
          Value,
          Weight,
        },
      },
    },
  } = vitals;

  // change non-read values to N/A
  Thermometer = (_.includes(RawData, 'thermometer')) ? Value : 'N/A';
  Glucose = (_.includes(RawData, 'glucose')) ? Value : 'N/A';
  Weight = (_.includes(RawData, 'weight')) ? Value : 'N/A';

  // format to data object
  const aneltoReading = {
    BPM,
    Oxygen: PulseAvg,
    Systolic,
    Diastolic,
    Thermometer,
    Glucose,
    Weight,
  };

  // remove junk
  const readData = _.pickBy(aneltoReading, o => !_.startsWith(o, 'N'));

  // return formatted vitals object
  return {
    ...readData,
  };
};

export default formatVitalsData;
