import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import GeneralInfo from '../components/GeneralInfo/GeneralInfo';


const GeneralInfoPage = ({ state = {}, dispatch }) => {
  const [questionsData, setQuestionsData] = useState({});
  return (
    <Layout
      state={state}
      // previousPath="/complete-activation"
      dispatch={dispatch}
      nextPath="/risk-assessment"
      introduction
      questionsData={questionsData}
    >
      <div className="app__content-box">
        <GeneralInfo state={state} dispatch={dispatch} setQuestionsData={setQuestionsData} />
      </div>
    </Layout>
  );
};

export default GeneralInfoPage;
