import React from 'react';
import RefreshDetected from '../components/RefreshDetected/RefreshDetected';
import Layout from '../components/Layout/Layout';

const Refresh = ({ state, dispatch }) => (
  <Layout>
    <div className="app__content-box">
      <RefreshDetected dispatch={dispatch} />
    </div>
  </Layout>

);

export default Refresh;
