import React, { useState, useEffect } from 'react';
import {
  Typography, Button, Table, Modal, Icon,
} from 'antd';
import { IoIosHome, IoIosPulse } from 'react-icons/io';
import { IconContext } from 'react-icons';
import Fade from 'react-reveal/Fade';
import { TiPhoneOutline } from 'react-icons/ti';
import { navigate } from '@reach/router';
import _ from 'lodash';
import cuid from 'cuid';
import { useLocalStorage } from 'standard-hooks';
import postThresholds from '../../API/postThresholds';
import checkThresholdsExist from '../../functions/checkThresholdsExist';

const { Title, Paragraph } = Typography;
const { confirm } = Modal;

/**
 * @desc Confirm vital thresholds and either return Home or continue to Intro questions
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @prop {Object}  state - global state of patient data
 * @prop {function} setPath - defines url path
 */

const CompleteActivation = ({ state = {}, setPath, dispatch }) => {
  const { thresholds, devices } = state;
  const [thresholdsConfirmed, setThresholdsConfirmed] = useState(false);
  const [displayThresholds, setDisplayThresholds] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  let tableData = [];

  /**
 * @desc sendThresholds - 
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @returns 
 */

  const DataToPretty = {
    pulseOx: 'oxygen',
    weight: 'weight',
    thermometer: 'thermometer',
    glucose: 'glucose',
    bloodPressure: 'bloodPressure',
  };
  const sendThresholds = async () => {
    try {
      setErrorMessage(null);
      const response = await postThresholds(state);
      console.log('postThresholds response: ', response);
      if (response[0].data.statusCode === 200) {
        setThresholdsConfirmed(true);
      } else {
        setErrorMessage(response[0].data.message);
      }
    } catch (err) {
      console.log('Thresholds error: ', err);
    }
  };
  const callModal = () => {
    confirm({
      title: 'Customer Service Contact Info',
      content: (
        <div>
          <h3>Contact us</h3>

          <Icon type="home" />
          <h4>Address:</h4>
          <p>506 S. Main Street, Suite 1000</p>
          <p>Las Cruces, NM 88001</p>

          <Icon type="phone" />
          <h4>Phone:</h4>
          <p>(833)-324-5433 ext. 2</p>

          <Icon type="mail" />
          <h4>Email:</h4>
          <p>support@ecg-hq.com</p>
        </div>),
      onOk() {
      },
    });
  };
  const columns = [
    {
      title: 'Vital Name',
      dataIndex: 'vital',
    },
    {
      title: 'Minimum',
      dataIndex: 'min',
      editable: true,
    },
    {
      title: 'Maximum',
      dataIndex: 'max',
      editable: true,
    },
  ];
  /**
   * @desc tableData contains all thresholds data that is entered by the user
   * and places them into an antDesign table
   * @prop {array} thresholds - Destructured from state
   * @return all of the threshold data set into appropriate objects to be displayed in a table
   */
  const newThresholds = {};
  _.forEach(thresholds, (thresholdValue, thresholdName) => {
    _.forEach(devices, (deviceName) => {
      if (DataToPretty[deviceName] === thresholdName) {
        newThresholds[thresholdName] = thresholdValue;
      }
    });
  });
  console.log('NewThresholds: ', newThresholds);
  tableData = _.map(newThresholds, (vitalValue, vitalName) => {
    if (vitalName === 'bloodPressure') {
      return (
        {
          key: cuid(),
          vital: 'Diastolic',
          min: vitalValue.diastolic.min,
          max: vitalValue.diastolic.max,
        });
    }
    if (vitalName === 'oxygen') {
      return (
        {
          key: cuid(),
          vital: 'Oxygen',
          min: vitalValue.oxygen.min,
          max: vitalValue.oxygen.max,
        }
      );
    }
    if (vitalName === 'thermometer') {
      return (
        {
          key: cuid(),
          vital: 'Temperature',
          min: vitalValue.min,
          max: vitalValue.max,
        }
      );
    }
    return {
      key: cuid(),
      vital: _.capitalize(vitalName),
      min: vitalValue.min,
      max: vitalValue.max,
    };
  });
  if (newThresholds.bloodPressure) {
    tableData.push({
      key: cuid(),
      vital: 'Systolic',
      min: newThresholds.bloodPressure.systolic.min,
      max: newThresholds.bloodPressure.systolic.max,
    });
  }
  if (newThresholds.oxygen) {
    tableData.push({
      key: cuid(),
      vital: 'BPM',
      min: newThresholds.oxygen.bpm.min,
      max: newThresholds.oxygen.bpm.max,
    });
  }
  const [localState, setLocalState] = useLocalStorage('localState', state);
  useEffect(() => {
    if (JSON.stringify(state.patientData) !== 'null') setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);

  useEffect(() => {
    if (JSON.stringify(state.patientData) === 'null') {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
    const tempState = JSON.stringify(state.patientData) === 'null' ? localState : state;
    console.log('State within useEffect: ', state);
    const check = checkThresholdsExist(tempState);
    console.log('checkThresholds response: ', check);
    setDisplayThresholds(check);
  }, []);
  useEffect(() => {
    if (localState.patientData === null) {
      navigate('/404');
    }
  }, []);
  return (
    <div className="complete-activation__container">
      {displayThresholds
        ? null
        : (
          <IconContext.Provider value={{ size: 25, className: 'global-class-name' }}>
            <div className="complete-activation__container">
              <Title level={4}>Please Confirm the baseline thresholds</Title>
              <Table columns={columns} dataSource={tableData} pagination={false} />
              <Button
                className="complete-activation__button"
                hidden={thresholdsConfirmed}
                type="primary"
                shape="round"
                size="large"
                onClick={sendThresholds}
              >
                <IoIosPulse />
                &nbsp; Confirm baseline thresholds
              </Button>
            </div>
          </IconContext.Provider>
        )}

      {thresholdsConfirmed
      // eslint-disable-next-line no-mixed-operators
      && (
        <IconContext.Provider value={{ size: 25, className: 'global-class-name' }}>
          <Fade>
            <Title level={4}>Thresholds have been set. Vital devices are live.</Title>
            <Button
              className="complete-activation__button"
              type="primary"
              shape="round"
              size="large"
              onClick={() => {
                navigate('/');
                window.location.reload();
              }}
            >
              <IoIosHome />
              &nbsp; Return to Home
            </Button>
          </Fade>
        </IconContext.Provider>
      )
    }
      {errorMessage
        ? (
          <Paragraph>
            <Icon style={{ color: 'red' }} type="close-circle" />
            {' '}
            {errorMessage}
            {'    '}
            <Button type="link" onClick={callModal}>
            Contact Customer Service &nbsp;
              <TiPhoneOutline />

            </Button>
          </Paragraph>
        )
        : null }
      {displayThresholds
          && (
            <IconContext.Provider value={{ size: 25, className: 'global-class-name' }}>
              <Fade>
                <Title level={4}>Vital devices are live.</Title>
                <Button
                  className="complete-activation__button"
                  type="primary"
                  shape="round"
                  size="large"
                  onClick={() => {
                    navigate('/');
                    window.location.reload();
                  }}
                >
                  <IoIosHome />
                  &nbsp; Return to Home
                </Button>
              </Fade>
            </IconContext.Provider>
          )}
      {
      (thresholdsConfirmed || displayThresholds)
      && !state.proHealthOnly
        && (
          <IconContext.Provider value={{ size: 25, className: 'global-class-name' }}>
            <Fade>
              <Button
                className="complete-activation__button"
                type="primary"
                shape="round"
                size="large"
                onClick={() => navigate('/risk-assessment')}
              >
        Addison Care Questions
              </Button>
            </Fade>
          </IconContext.Provider>
        )

    }

    </div>
  );
};
export default CompleteActivation;
