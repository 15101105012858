import React, { useState, useEffect } from 'react';
import {
  Typography, Input, Radio, Divider, Button, Checkbox, Col, Icon,
} from 'antd';
import { navigate } from '@reach/router';
import { useLocalStorage } from 'standard-hooks';
import DisclaimerModal from '../DisclaimerModal/DisclaimerModal';

const { Title } = Typography;

/**
* @desc Survey questions determining the risk of health or falling issues
* @author Rick Provencio <rprovencio@ecg-hq.com>
* @prop {Object} state - global state of patient data
* @prop {function} setQuestionsData - creates object of risk assessment survey answers
* @prop {string} path - URL
*/
export const handleChange = (mutator, value) => {
  mutator(value);
}
const RiskAssessment = ({ setQuestionsData, state, dispatch }) => {
  const [chronicIllness, setChronicIllness] = useState(null);
  const [fallRisk, setFallRisk] = useState(null);
  const [fallThreeYears, setFallThreeYears] = useState(null);
  const [aloneThreeHours, setAloneThreeHours] = useState(null);
  const [medicationsPlusThree, setMedicationsPlusThree] = useState([null]);
  const [protectYoureself, setProtectYourself] = useState([null]);

  useEffect(() => {
    console.log(
      [
        chronicIllness,
        fallRisk,
        fallThreeYears,
        aloneThreeHours,
        medicationsPlusThree,
        protectYoureself,
      ],
    );
    setQuestionsData(
      {
        chronicIllness,
        fallRisk,
        fallThreeYears,
        aloneThreeHours,
        medicationsPlusThree,
        protectYoureself,
      },
    );
  },
  [
    chronicIllness,
    fallRisk,
    fallThreeYears,
    aloneThreeHours,
    medicationsPlusThree,
    protectYoureself,
  ]);

  const [localState, setLocalState] = useLocalStorage('localState', state);
  useEffect(() => {
    if (JSON.stringify(state.introQuestions) !== '{}') setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);

  useEffect(() => {
    if (JSON.stringify(state.introQuestions) === '{}') {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);
  useEffect(() => {
    console.log('State in useEffect: ',localState);
    if (localState.patientData === null) {
      navigate('/404');
    }
  }, []);
  return (
    <div className="introduction__container">
      <DisclaimerModal />
      <Title className="pro-health-or-addison__title" level={2}>
      Risk Assessment Survey
      </Title>
      <Divider />
      <h3 className="question">Do you have one or more chronic illnesses?</h3>
      <Radio.Group
        onChange={e => handleChange(setChronicIllness, e.target.value)}
      >
        <Radio value data-testid="illnessYes">Yes</Radio>
        <Radio value={false} data-testid="illnessNo">No</Radio>
      </Radio.Group>

      <h3 className="question">Are you at risk of falling?</h3>
      <Radio.Group
        onChange={e => handleChange(setFallRisk, e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>

      <h3 className="question">Have you fallen in the past three years?</h3>
      <Radio.Group
        onChange={e => setFallThreeYears(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>

      <h3 className="question">Do you spend more than three hours alone per day?</h3>
      <Radio.Group
        onChange={e => setAloneThreeHours(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>

      <h3 className="question">Do you take three or more medications per day?</h3>
      <Radio.Group
        onChange={e => setMedicationsPlusThree(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>

      <h3 className="question">Do you worry about protecting yourself from a home intruder or worry about burglaries?</h3>
      <Radio.Group
        onChange={e => setProtectYourself(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
    </div>
  );
};

export default RiskAssessment;
