import axios from 'axios';

const getUsersInGroups = async (groups, token) => {
  const { data } = await axios.get('https://wrmp3ihhb4.execute-api.us-east-1.amazonaws.com/dev', {
    params: {
      groups: JSON.stringify(groups),
    },
    headers: {
      Accept: 'token',
      token,
    },
  }).catch(err => console.error(err));
  return data;
};
export default getUsersInGroups;
