import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import BloodPressure from '../components/BloodPressure/BloodPressure';

const BloodPressurePage = ({ state = {}, dispatch }) => {
  const [path, setPath] = useState('');
  return (
    <Layout
      state={state}
      previousPath="/vitals"
      dispatch={dispatch}
      nextPath={path}
      back
    >
      <div className="app__content-box">
        <BloodPressure state={state} dispatch={dispatch} setPath={setPath} />
      </div>
    </Layout>
  );
};
export default BloodPressurePage;
