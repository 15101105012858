import React, { useReducer, useEffect, useState } from "react";
import IdleTimer from "react-idle-timer";
import { Router } from "@reach/router";
import { Auth, I18n } from "aws-amplify";
import {
  withAuthenticator,
  SignIn,
  ForgotPassword,
  VerifyContact,
  RequireNewPassword,
  ConfirmSignIn
} from "aws-amplify-react";
import Default from "./pages/Default";
import Refresh from "./pages/Refresh";
import ProHealthorAddisonPage from "./pages/ProHealthorAddisonPage";
import ActivationSearchPage from "./pages/ActivationSearchPage";
import RapidActivationPage from "./pages/RapidActivationPage";
import TestSignalPage from "./pages/TestSignalPage";
import VitalsPage from "./pages/VitalsPage";
import GlucosePage from "./pages/GlucosePage";
import WeightPage from "./pages/WeightPage";
import ThermometerPage from "./pages/ThermometerPage";
import PulseOxPage from "./pages/PulseOxPage";
import BloodPressurePage from "./pages/BloodPressurePage";
import CompleteActivationPage from "./pages/CompleteActivationPage";
import reducer from "./GlobalState/Reducer";
import globalState from "./GlobalState/GlobalState";
import GeneralInfoPage from "./pages/GeneralInfoPage";
import RiskAssesmentPage from "./pages/RiskAssessmentPage";
import EmergencyContactPage from "./pages/EmergencyContactPage";
import GeneralHealthPage from "./pages/GeneralHealthPage";
import HealthcareProviderPage from "./pages/HealthcareProviderPage";
import MedicalHistoryPage from "./pages/MedialHistoryPage";
import RemindersPage from "./pages/RemindersPage";
import DailyNutritionPage from "./pages/DailyNutritionPage";
import HomeWellbeingPage from "./pages/HomeWellbeingPage";
import CreateAddisonUserPage from "./pages/CreateAddisonUserPage";
import MedicalOverviewPage from "./pages/MedicalOverviewPage";

let localState = JSON.parse(localStorage.getItem("localState"));

const App = () => {
  const [state, dispatch] = useReducer(reducer, globalState);
  const [token, setToken] = useState("");

  const getUsername = async () => {
    const currentSession = await Auth.currentSession();
    console.log("Current Session: ", currentSession);
    const {
      idToken: { payload, jwtToken }
    } = currentSession;
    const username = payload["cognito:username"];
    dispatch({
      type: "UPDATE_LOGGED_IN_DEALER",
      payload: username.toUpperCase()
    });
    setToken(jwtToken);
  };

  console.log("state in app: ", state);
  //console.log("Local State in app: ", localState);
  if (localState === null) {
    localState = { devices: [] };
    localState.devices[0] = "false";
    console.log("Devices localstate: ", localState.devices);
  }
  useEffect(() => {
    getUsername();
  }, []);

  return (
    <div>
      <IdleTimer
        timeout={1800000}
        onIdle={async e => {
          console.log("time left on idle");
          await Auth.signOut();
          await window.location.reload(true);
        }}
      />
      <Router>
        <Default default />
        <Refresh path="/404" />
        <ProHealthorAddisonPage path="/" state={state} dispatch={dispatch} />
        <ActivationSearchPage path="/activation-search" state={state} dispatch={dispatch} token={token} />
        <MedicalOverviewPage path="/medical-overview" state={state} dispatch={dispatch} />
        <RemindersPage path="/reminders" state={state} dispatch={dispatch} />
        <RapidActivationPage path="/rapid-activation" state={state} dispatch={dispatch} />
        <TestSignalPage path="/test-signal" state={state} dispatch={dispatch} />
        <VitalsPage path="/vitals" state={state} dispatch={dispatch} />

        {/* Render page 6 conditionally */}
        {(state.devices.includes('glucose') || localState.devices.includes('glucose')) && <GlucosePage path="/glucose" state={state} dispatch={dispatch} />}
        {(state.devices.includes('weight') || localState.devices.includes('weight')) && <WeightPage path="/weight" state={state} dispatch={dispatch} />}
        {(state.devices.includes('thermometer') || localState.devices.includes('thermometer')) && <ThermometerPage path="/thermometer" state={state} dispatch={dispatch} />}
        {(state.devices.includes('pulseOx') || localState.devices.includes('pulseOx')) && <PulseOxPage path="/pulseOx" state={state} dispatch={dispatch} />}
        {(state.devices.includes('bloodPressure') || localState.devices.includes('bloodPressure')) && <BloodPressurePage path="/bloodPressure" state={state} dispatch={dispatch} />}
        <CompleteActivationPage path="/complete-activation" state={state} dispatch={dispatch} />
        <GeneralInfoPage path="/general-info" state={state} dispatch={dispatch} />
        <RiskAssesmentPage path="/risk-assessment" state={state} dispatch={dispatch} />
        <EmergencyContactPage path="/emergency-contact" state={state} dispatch={dispatch} />
        <GeneralHealthPage path="/general-health" state={state} dispatch={dispatch} />
        <HealthcareProviderPage path="/healthcare-provider" state={state} dispatch={dispatch} />
        <MedicalHistoryPage path="/medical-history" state={state} dispatch={dispatch} />
        <DailyNutritionPage path="/daily-nutrition" state={state} dispatch={dispatch} />
        <HomeWellbeingPage path="/home-wellbeing" state={state} dispatch={dispatch} />
        <CreateAddisonUserPage path="/create-addison-user" state={state} dispatch={dispatch} />
        <MedicalOverviewPage path="/medical-overview" state={state} dispatch={dispatch} />
        
      </Router>
    </div>
  );
};
const Theme = {
  button: { backgroundColor: "#4BCBF3", borderRadius: "25%" },
  formContainer: { color: "#fff", height: "100%" },
  backgroundColor: { backgroundColor: "#fff" },
  a: { color: "rgb(75, 203, 243)" }
};

// replace screen label on Authenticator component
const authScreenLabels = {
  en: {
    "Sign in to your account": "ECG Device Activation"
  }
};

I18n.setLanguage("en");
I18n.putVocabularies(authScreenLabels);

export default withAuthenticator(
  App,
  false,
  [
    <SignIn />,
    <ForgotPassword />,
    <ConfirmSignIn />,
    <VerifyContact />,
    <RequireNewPassword />
  ],
  null,
  Theme
);
