import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Typography, Button, Icon, Modal, Spin } from 'antd';
import { useLocalStorage } from 'standard-hooks';
import { navigate } from '@reach/router';
import { TiPhoneOutline } from 'react-icons/ti';
import _ from 'lodash';
import postAddisonCreateUser from '../../API/postAddisonCreateUser';
import CustomerServiceModal from '../CustomerServiceModal/CustomerServiceModal';
import postReminders from '../../API/postReminders';

const { Title, Paragraph, Text } = Typography;

/**
 * @desc Displays sections to correct information with customer service.
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @prop {Object}  state - global state of patient data
 * @prop {function} setPath - defines url path
 * @prop {string} path - URL
 */
const CreateAddisonUser = ({ setPath, state = {}, path, dispatch }) => {
  const [endModal, hideEndModal] = useState(true);
  const [spinner, setSpinner] = useState(false);
  const [fail, setFail] = useState(false);
  const [done, setDone] = useState(false);
  const [questionsError, setQuestionsError] = useState(null);
  const [medsError, setMedsError] = useState(null);
  const { confirm } = Modal;
  const callModal = () => {
    confirm({
      title: 'Customer Service Contact Info',
      content: (
        <div>
          <h3>Contact us</h3>

          <Icon type="home" />
          <h4>Address:</h4>
          <p>506 S. Main Street, Suite 1000</p>
          <p>Las Cruces, NM 88001</p>

          <Icon type="phone" />
          <h4>Phone:</h4>
          <p>(833)-324-5433 ext. 2</p>

          <Icon type="mail" />
          <h4>Email:</h4>
          <p>support@ecg-hq.com</p>
        </div>
      ),
      onOk() {},
    });
  };
  const [localState, setLocalState] = useLocalStorage('localState', state);
  useEffect(() => {
    if (JSON.stringify(state.introQuestions) !== '{}') setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);

  useEffect(() => {
    if (JSON.stringify(state.introQuestions) === '{}') {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);
  useEffect(() => {
    if (localState.patientData === null) {
      navigate('/404');
    }
  }, []);

  return (
    <div className="Create-Addison-User__Layout">
      {!done && (
        <Title className="Create-Addison-User__Title" level={2}>
          Create Addison User
        </Title>
      )}
      {endModal ? <CustomerServiceModal state={state} path={path} /> : null}
      {fail ? (
        <Fade>
          <Title className="Create-Addison-User__Oops" level={2}>
            Oops, something went wrong.
          </Title>
          <div className="desc">
            {questionsError ? (
              <Paragraph>
                <Icon style={{ color: 'red' }} type="close-circle" />
                Addison questions Error - {questionsError}
                {'    '}
              </Paragraph>
            ) : null}

            {medsError ? (
              <Paragraph>
                <Icon style={{ color: 'red' }} type="close-circle" />
                Reminders Error - {medsError}
                {'    '}
              </Paragraph>
            ) : null}
            <Paragraph>
              <Button type="link" onClick={callModal}>
                Contact Customer Service <TiPhoneOutline />
              </Button>
            </Paragraph>
          </div>
        </Fade>
      ) : null}
      {spinner ? <Spin size="large" /> : null}
      {done ? (
        <Fade big>
          <Title level={2}>We're all done, thanks!</Title>
          <img
            key={4}
            src="https://addison-project-anim-files.s3.amazonaws.com/UI+Images/Basic+Pictures/Addison_ThumbsUp_v01+cropped.png"
            className="Create-Addison-User__image"
            alt="Addison_ThumbsUp"
          />
        </Fade>
      ) : (
        <Button
          className="Create-Addison-User__Button"
          type="primary"
          shape="round"
          size="large"
          /**
           * @desc Submit User Activation onClick -
           * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
           * @returns
           */
          onClick={async () => {
            setSpinner(true);
            setFail(null);
            hideEndModal(false);
            try {
              const createUser = await postAddisonCreateUser(state);
              const reminders = await postReminders(state);
              console.log('Createuser: ', createUser);
              console.log('reminders: ', reminders);
              setSpinner(false);
              // Commented section out, this section was for error handling.
              // Currently doesn't have that has to do with the lambdas

              // console.log('CreateUser.status:', createUser.status);
              // if (createUser.status === 200 && reminders.status === 200) {
              //   console.log('API send Data:', createUser.status);
              //   console.log('Reminders api send data: ', reminders);
              //   setDone(true);
              // } else {
              //   console.log('Error');
              //   setFail(true);
              //   setQuestionsError(createUser.status.toString());
              //   setMedsError(reminders.status.toString());
              // }
              setDone(true);
            } catch (err) {
              setSpinner(false);
              console.error(err);
              setFail(true);
              setQuestionsError(err.toString());
              setMedsError(err.toString());
            }
          }}
        >
          Submit User Activation
        </Button>
      )}
    </div>
  );
};
export default CreateAddisonUser;
