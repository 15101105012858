import axios from 'axios';
import _ from 'lodash';

const getDealerGroups = async (dealerID) => {
  const { data } = await axios.get('https://j6w69mgqwi.execute-api.us-east-1.amazonaws.com/prod', {
    params: {
      dealerID,
    },
    headers: {
      'x-api-key': 'wqWJ0M3ale2OsJyIIfo8y4u7gB9qqxUY1nkOaq6B',
    },
  }).catch(err => console.error(err));

  return data.groups.length === 0 ? [data.dealerID] : _.map(data.groups, 'group');
};
export default getDealerGroups;
