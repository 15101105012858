import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import HealthcareProvider from '../components/HealthcareProvider/HealthcareProvider';


const HealthcareProviderPage = ({ state = {}, dispatch }) => {
  const [questionsData, setQuestionsData] = useState({});
  return (
    <Layout
      state={state}
      previousPath="/general-health"
      dispatch={dispatch}
      nextPath="/medical-history"
      introduction
      questionsData={questionsData}
    >
      <div className="app__content-box">
        <HealthcareProvider state={state} dispatch={dispatch} setQuestionsData={setQuestionsData} />
      </div>
    </Layout>
  );
};


export default HealthcareProviderPage;
