/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Fade from 'react-reveal/Fade';
import {
  Typography, Select, Button, Input, Table, message, Spin,
} from 'antd';
import _ from 'lodash';
import { API, graphqlOperation } from 'aws-amplify';
import { navigate } from '@reach/router';
import getCareProfile from '../../API/graphql/getCareProfile';
import getDealerGroups from '../../API/getDealerGroups';
import getUsersInGroups from '../../API/getUsersInGroups';

const { Title } = Typography;
const { Option } = Select;
let columns = [];
let data = [];

/**
 * @desc Search for patient in group.
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @prop {Object}  state - global state of patient data
 * @prop {function} dispatch - a reducer of state object
 * @prop {function} setPath - defines url path
 * @prop {string} token - authentication token from API Gateway
 */
const ActivationSearch = ({ setPath, state, dispatch, token,}) => {
  //const xmitRegEx = /[A][N][Z]\d{4}/;
  const xmitRegEx = /[A][N]/;

  const [ready, setReady] = useState(false);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  /**
   * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
   * get the idToken of the logged in user (only runs within initial render)
   * token is used for user/group API calls
  */
  const getAuthToken = async () => {
    const currentSession = await Auth.currentSession();
    const { idToken: { jwtToken } } = currentSession;
    return jwtToken;
  };
  /**
 * @desc getCustomers - 
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @returns 
 */
  const getCustomers = async () => {
    try {
      const groups = await getDealerGroups(state.loggedInDealerID).catch(err => console.error(err));
      console.log('groups', groups);
      const jwtToken = await getAuthToken();
      const customers = await getUsersInGroups(groups, jwtToken)
        .then((customers) => {
          console.log('Customers: ', customers);
          if (customers[0]) setCustomerOptions(customers);
        })
        .catch(err => console.error(err));
      console.log('customers', customers);
      setLoading(false);
    } catch (err) {
      navigate('/');
      console.error(err);
    }
  };


  useEffect(() => {
    getCustomers();
  }, [state]);

  const checkRegEx = () => {
    columns = [
      {
        title: ' ',
        dataIndex: 'column1',
      },
      {
        title: ' ',
        dataIndex: 'column2',
      },
    ];

    data = [
      {
        key: '1',
        column1: 'Name: ',
        column2: state.patientData.data.firstName.concat(' ').concat(state.patientData.data.lastName),
      },
      {
        key: '2',
        column1: 'Device ID: ',
        column2: state.xmitID,
      },
      {
        key: '3',
        column1: 'Device: ',
        column2: state.proHealthOnly ? 'Pro Health' : 'Addison + Pro Health',
      },
      {
        key: '4',
        column1: 'Address: ',
        column2: state.patientData.data.address.street,
      },
    ];
    if (xmitRegEx.test(state.xmitID)) {
      setReady(true);
      setPath('/medical-overview');
    } else {
      setReady(false);
      message.error('Device ID must be a valid ANZ');
    }
  };
  return (
    <div className="activation__search-container">
      <Title className="activation__search-title" level={2}>
          Patient Search
      </Title>
      {loading ? (
        <Spin className="activation__search-input" />
      )
        : (
          /**
           * @desc Customer Select onChange - 
           * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
           * @returns 
           */
          <>
            <Select
              className="activation__search-select"
              data-testid="activation-search-select"
              placeholder="Patient Name"
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              showSearch
              onChange={async (value) => {
                try {
                  const { data: { getCareProfile: { profileDetails } } } = await API.graphql(graphqlOperation(getCareProfile, { id: `${value}-care-profile-id` }));
                  dispatch({
                    type: 'UPDATE_PATIENT_DATA',
                    payload: (() => {
                      const profile = _.find(customerOptions, ['id', value]);
                      return { ...profile, ...profileDetails };
                    })(),
                  });
                } catch (err) {
                  dispatch({
                    type: 'UPDATE_PATIENT_DATA',
                    payload: _.find(customerOptions, ['id', value]),
                  });
                }
              }
            }
            >
              { customerOptions.map(customer => (
                // should be false
                (customer.active === 'false')
                  ? (
                    <Option key={`key-${customer.id}`} value={customer.id}>
                      {`${customer.data.firstName} ${customer.data.lastName} ${customer.data.dob}`}
                    </Option>
                  )
                  : null
              ))
        }
            </Select>
            <Input
              className="activation__search-input"
              disabled={!state.patientData}
              maxLength={7}
              defaultValue="AN"
              placeholder="Device ID"
              onChange={(e) => {
                e.persist();
                dispatch({ type: 'UPDATE_XMIT', payload: e.target.value });
              }}
            />
            <Button
              className="activation__search-ready-button"
              type="primary"
              disabled={!state.patientData}
              size="large"
              onClick={() => checkRegEx()}
            >
          Confirm Patient Information
            </Button>

            { ready
        && (
        <Fade>
          <div className="activation__search-patientinfo">
            <h2>Patient Info</h2>
            <Table columns={columns} dataSource={data} pagination={false} showHeader={false} />
          </div>
        </Fade>
        )
      }
          </>
        )
    }
    </div>
  );
};

export default ActivationSearch;
