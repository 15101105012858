import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import GeneralHealth from '../components/GeneralHealth/GeneralHealth';


const GeneralHealthPage = ({ state = {}, dispatch }) => {
  const [questionsData, setQuestionsData] = useState({});
  return (
    <Layout
      state={state}
      previousPath="/emergency-contact"
      dispatch={dispatch}
      nextPath="/healthcare-provider"
      introduction
      questionsData={questionsData}
    >
      <div className="app__content-box">
        <GeneralHealth state={state} dispatch={dispatch} setQuestionsData={setQuestionsData} />
      </div>
    </Layout>
  );
};

export default GeneralHealthPage;
