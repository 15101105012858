import React, { useEffect } from 'react';
import { Typography, Checkbox } from 'antd';
import { useLocalStorage } from 'standard-hooks';
import { navigate } from '@reach/router';
import _ from 'lodash';
import vitalsPath from '../../functions/vitalsPath';


const { Title } = Typography;

/**
* @desc the user indicates which peripherals they are using with their prohealth system
* @author Hector Rodriguez <hrodriguez@ecg-hq.com>
* @prop {Object} state - global state of patient data
* @prop {function} dispatch - a reducer of state object
* @prop {function} setPath - defines url path
*/

const Vitals = ({ setPath, state = {}, dispatch }) => {
  const devices = [
    {
      label: 'Glucometer',
      value: 'glucose',
    },
    {
      label: 'Weight Scale',
      value: 'weight',
    },
    {
      label: 'Thermometer',
      value: 'thermometer',
    },
    {
      label: 'Pulse Oximeter',
      value: 'pulseOx',
    },
    {
      label: 'Blood Pressure Cuff',
      value: 'bloodPressure',
    }];

  const onChange = (values) => {
    console.log(values);
    dispatch({ type: 'UPDATE_DEVICES', payload: values });
    if (state.devices !== []) {
      const path = vitalsPath(values);
      console.log('Path: ', path);
      setPath(path);
    }
  };
  const [localState, setLocalState] = useLocalStorage('localState', state);
  useEffect(() => {
    if (JSON.stringify(state.patientData) !== 'null') setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);

  useEffect(() => {
    if (JSON.stringify(state.patientData) === 'null') {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);
  useEffect(() => {
    if (localState.patientData === null) {
      navigate('/404');
    }
  }, []);
  return (
    <div className="vitals__layout">
      <Title className="" level={2}>Vitals Devices</Title>
      <Title className="" level={4}>Please select the user's ordered devices</Title>
      <Checkbox.Group size="large" className="vitals__buttons" options={devices} onChange={onChange} />
    </div>
  );
};
export default Vitals;
