import React, {
  useState,
  useEffect,
} from 'react';
import {
  Typography, Button, Spin,
} from 'antd';
import ReactPlayer from 'react-player';
import _ from 'lodash';
import { API, graphqlOperation } from 'aws-amplify';
import { useLocalStorage } from 'standard-hooks';
import vitalsPath from '../../functions/vitalsPath';
import formatVitalsData from '../../API/formatVitalsData';
import subscription from '../../API/graphql/subscribeToAneltoEvents';
import Threshold from '../Threshold/Threshold';

const { Title } = Typography;

/**
* @desc thermometer tutorial and first reading in the Activation section
* @author Dakota Marquez <dmarquez@ecg-hq.com>
* @author Hector Rodriguez <hrodriguez@ecg-hq.com>
* @prop {Object} state - global state of patient data.
* @prop {function} dispatch - a reducer of state object
* @prop {function} setPath - defines url path.
*/

const Thermometer = ({ setPath, state = {}, dispatch }) => {
  const defaultState = { Thermometer: null };
  const [reading, setReading] = useState(defaultState);
  
  // the states of what content is being rendered
  const [readingView, setReadingView] = useState(true);
  const [viewThreshold, setViewThreshold] = useState(true);
  
  /**
 * @desc Thermometer useEffect - 
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @returns 
 */

  useEffect(() => {
    const subscribe = API.graphql(graphqlOperation(subscription, { Account: state.xmitID }))
      .subscribe({
        next: (data) => {
          console.log('Vitals Data', formatVitalsData(data));
          setReading(formatVitalsData(data));
        },
      });
    return () => subscribe.unsubscribe();
  }, [state.xmitID]);

  const takeReading = () => {
    if (reading.Thermometer !== null){
    console.log('temperature reading successfully taken');
    setReadingView(false);
    const remainingReadings = vitalsPath(
      _.filter(
        state.devices, device => device !== 'thermometer' && device !== 'weight' && device !== 'glucose',
      ),
    );
    if (remainingReadings === undefined) {
      setPath('/complete-activation');
    } else {
      setPath(remainingReadings);
    }
  }};
  const resetReading = () => {
    console.log('resetting temperature reading');
    setReadingView(true);
    setReading(defaultState);
  };
  const [localState, setLocalState] = useLocalStorage('localState', state);
  useEffect(() => {
    if (JSON.stringify(state.patientData) !== 'null') setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);

  useEffect(() => {
    if (JSON.stringify(state.patientData) === 'null') {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);
  useEffect(() => {
    dispatch({
      type: 'UPDATE_THERMOMETER_THRESHOLD',
      payload: { min: '95', max: '99' },
    });
  }, []);
  useEffect(() => {
    takeReading();
  }, [reading.Thermometer]);
  return (
    <div className="activation-tutorials__container">
      <Title level={2}>
              Thermometer Tutorial
      </Title>
      <div className="responsive-image">
        <ReactPlayer
          url="https://addison-project-anim-files.s3.amazonaws.com/Videos/VitalsTutorials/ThermometerTutorial_v03.mp4"
          controls
          height="100%"
          width="100%"
        />
      </div>
      <br />
      <br />
      <div>
        <Title level={4} className="activation-tutorials__text">
          Please use your thermometer when you are ready.
          <br />
          Your reading is:
        </Title>
        {reading.Thermometer === null ? (
          <div className="activation-tutorials__spinner">
            {' '}
            <Spin />
            {' '}
          </div>
        )
          : (
            <div className="activation-tutorials__vital-feedback">
              {reading.Thermometer} &#8457;
              <br />
            </div>
          )}
          {reading.Thermometer !== null
            ? (
              <>
                <div className="activation-tutorials__text">
  Your thresholds have been set to the recommended standard:
  <br />
  87.8 &#8457; to 99.1 &#8457;
                </div>
                <Button
                  type="link"
                  // shows threshold input fields by rendering Threshold.jsx component
                  onClick={() => setViewThreshold(false)}
                  hidden={readingView}
                  className="activation-tutorials__threshold-button"
                >
                  Set Personal Thresholds
                </Button>
                {' '}
              </>
            ) : null}
          <div className="activation-tutorials__threshold-reading">
            <Button
              type="primary"
              shape="round"
              size="large"
              onClick={resetReading}
              hidden={readingView}
            >
              Retake Reading
            </Button>
          </div>
      </div>
      <br />
      <Threshold vitalType="thermometer" dispatch={dispatch} vitalName="THERMOMETER" hidden={viewThreshold} />
    </div>
  );
};

export default Thermometer;
