import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import ActivationSearch from '../components/ActivationSearch/ActivationSearch';

const ActivationSearchPage = ({ state = {}, dispatch, token }) => {
  const [path, setPath] = useState('');

  return (
    <Layout
      state={state}
      previousPath="/"
      dispatch={dispatch}
      nextPath={path}
    >
      <div className="app__content-box">
        <ActivationSearch
          state={state}
          dispatch={dispatch}
          setPath={setPath}
          token={token}
        />
      </div>
    </Layout>
  );
};

export default ActivationSearchPage;
