/* eslint-disable consistent-return */
import _ from 'lodash';

const checkThresholdsExist = (state = {}) => {
  const { thresholds } = state;
  const vitals = _.filter(thresholds, (threshold) => {
    if (threshold.min) {
      return threshold.min;
    }
  });
  const bloodVitals = _.filter(thresholds.bloodPressure, (threshold) => {
    if (threshold.min) {
      return threshold.min;
    }
  });
  const oxygenVitals = _.filter(thresholds.oxygen, (threshold) => {
    if (threshold.min) {
      return threshold.min;
    }
  });
  return (
    _.isEmpty(vitals) && _.isEmpty(bloodVitals) && _.isEmpty(oxygenVitals)
  );
};

export default checkThresholdsExist;
