import React, { useState } from 'react';
import { Modal, Button, Icon } from 'antd';

/**
 * @desc automatically pops up at the end of the introduction section with items that need to be addressed with customer service and their contact info.
 * @author Dakota Marquez <dmarquez@ecg-hq.com>
 * @prop {Object}  state - global state of patient data
 * @prop {string} path - URL
 */
const CustomerServiceModal = ({ state = {}, path }) => {
  let noIncorrectItems = { hidden: false };
  let incorrectItems = { hidden: true };
  let emergencyContact = { hidden: true };
  let primaryCarePractitioner = { hidden: true };

  //this determines which text get displayed depending on whether the user's information was correct or not, determined by the user in previous sections
  //the first if statement is checking to see if either the emergency contact information or the primary care information was incorrect
  if (state.introQuestions.emergencyContactConfirm === false
    || state.introQuestions.primaryCare === false) {
    noIncorrectItems = { hidden: true };
    incorrectItems = { hidden: false };
    if (state.introQuestions.emergencyContactConfirm === false) {
      emergencyContact = { hidden: false };
    }
    if (state.introQuestions.primaryCare === false) {
      primaryCarePractitioner = { hidden: false };
    }
  } else {
    noIncorrectItems = { hidden: false };
    incorrectItems = { hidden: true };
  }

  return (
    <div className="customer-service__container">
      {/* <Modal
        title="Customer Service Contact Info"
        centered
        visible={modalVisible}
        footer={[
          <Button key="ok" type="primary" onClick={() => setModalVisible(false)}>
            Ok
          </Button>,
        ]}
      > */}
      <div hidden={path !== '/create-addison-user'}>
        <h3>Congratulations!</h3>

        <div hidden={noIncorrectItems.hidden}>
          <p>You have completed your Addison User Questions!</p>
          <p>If you have any questions or concerns, please contact Customer Support below.</p>
          <p>When you are ready to submit all of your answers, just click "Submit User Activation" button.</p>
        </div>

        <div hidden={incorrectItems.hidden}>
          <p>You're almost there!</p>
          <p>These items below will need further attention with our customer support team:</p>

          <ul>
            <li hidden={emergencyContact.hidden}>Emergency Contact</li>
            <li hidden={primaryCarePractitioner.hidden}>Primary Care Practitioner</li>
          </ul>
        </div>
      </div>

      <h3>Contact us</h3>


      <h4>
        <Icon type="home" />
              &nbsp;Address:
      </h4>
      <p>506 S. Main Street, Suite 1000</p>
      <p>Las Cruces, NM 88001</p>


      <h4>
        <Icon type="phone" />
            &nbsp;Phone:
      </h4>
      <p>(833)-324-5433 ext. 2</p>

      <h4>
        <Icon type="mail" />
            &nbsp;Email:
      </h4>
      <p>support@ecg-hq.com</p>
      {/* </Modal> */}
    </div>
  );
};

export default CustomerServiceModal;
