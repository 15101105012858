import React, { useState } from 'react';
import { Modal, Button, Icon } from 'antd';

/**
 * @desc Disclaimer to the customer regarding sensitive data information.
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 */
const DisclaimerModal = () => {
  const [modalVisible, setModalVisible] = useState(true);

  return (
    <div>
      <Modal
        title="Addison Questions Disclaimer"
        centered
        width="500px"
        visible={modalVisible}
        footer={[
          <Button key="ok" type="primary" onClick={() => setModalVisible(false)}>
                    Ok
          </Button>,
        ]}
      >
        <div>
          <h3>
            <p>
               You will now be answering questions about your health history, general behavior,
               and desires regarding care.
            </p>
            <p>
               Your responses will help guide our care for you
               and show us how to serve you better.
            </p>
            <p>Some of these questions will address sensitive topics and issues.</p>
            <p>
               You are not required to answer all of the following questions
               and may leave any of them blank if you wish.
            </p>
          </h3>
        </div>
      </Modal>
    </div>

  );
};

export default DisclaimerModal;
