import React, { useState, useEffect } from 'react';
import { useLocalStorage } from 'standard-hooks';
import { navigate } from '@reach/router';
import _ from 'lodash';
import moment from 'moment';
import { Input, Typography, DatePicker, Divider, Button, Icon, Radio, Select, Menu, Dropdown } from 'antd';
import MedicalConditions from './MedicalConditions';
import Allergies from './Allergies';
import globalStateReducer from '../../GlobalState/Reducer';
import postMedicalOverview from './../../API/postOverview';

const { Title } = Typography;
const { Option } = Select;

const InputGroup = Input.Group;

/**
 * @desc Search for allergyDescription within allergy array
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @prop {String}  code - Allergy code number
 * @prop {Array} allergyArray - Allergy Array that contains objects of codes and descriptions of allergies
 */

export const searchForAllergy = (code, allergyArray) => {
  let description;
  // eslint-disable-next-line array-callback-return
  allergyArray.map(({ AllergyCode, AllergyDescription }) => {
    if (code === AllergyCode) {
      description = AllergyDescription;
    }
  });
  return description;
};

/**
 * @desc Search for medicalConditionDescription within allergy array
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @prop {String}  code - Medical Condition code number
 * @prop {Array} conditionArray - MedicalConditions Array that contains objects of codes and descriptions of Medical conditions
 */

export const searchForCondition = (code, conditionArray) => {
  let description;
  // eslint-disable-next-line array-callback-return
  conditionArray.map(
    ({ MedicalConditionCode, MedicalConditionDescription }) => {
      if (code === MedicalConditionCode) {
        description = MedicalConditionDescription;
      }
    }
  );
  return description;
};

const MedicalOverview = ({ state, dispatch, setOverview, setPath }) => {
  const [medicalCondition, setMedicalConditions] = useState([
    { conditionCode: '', conditionDescription: '', comment: '' },
  ]);
  const [surgeries, setSurgeries] = useState([{ date: '', procedure: '', comment: '' }]);
  const [allergies, setAllergies] = useState([
    { allergyCode: '', allergyDescription: '', comment: '' },
  ]);
  const [heightFeet, setHeightFeet] = useState({ hidden: true });
  const [heightInches, setHeightInches] = useState({ hidden: true });
  const [weight, setWeight] = useState({ hidden: true });
  const [bloodType, setBloodType] = useState([{ bloodType: '' }]);
  const [bloodTypeRH, setBloodTypeRH] = useState([{ bloodTypeRH: '' }]);

  const [showBloodType, viewBloodType] = useState(false);
  const [showMedConditions, viewMedConditions] = useState(false);
  const [showSurgeriesProcedures, viewSurgeriesProcedures] = useState(false);
  const [showAllergies, viewAllergies] = useState(false);
  const medicalConditionArray = MedicalConditions;
  const allergiesArray = Allergies;
  setPath('/reminders');

  useEffect(() => {
    setOverview({ bloodType, bloodTypeRH, heightFeet, heightInches, weight, medicalCondition, surgeries, allergies });
    console.log("medicalCondition: ", medicalCondition);
  }, [medicalCondition]);
  useEffect(() => {
    setOverview({ bloodType, bloodTypeRH, heightFeet, heightInches, weight, medicalCondition, surgeries, allergies });
    console.log("Surgeries: ", surgeries);
  }, [surgeries]);
  useEffect(() => {
    setOverview({ bloodType, bloodTypeRH, heightFeet, heightInches, weight, medicalCondition, surgeries, allergies });
    console.log("Allergies:", allergies);
  }, [allergies]);
  useEffect(() => {
    setOverview({ bloodType, bloodTypeRH, heightFeet, heightInches, weight, medicalCondition, surgeries, allergies });
    console.log("bloodType:", bloodType);
  }, [bloodType]);
  useEffect(() => {
    setOverview({ bloodType, bloodTypeRH, heightFeet, heightInches, weight, medicalCondition, surgeries, allergies });
    console.log("bloodTypeRH:", bloodTypeRH);
  }, [bloodTypeRH]);  
  useEffect(() => {
    setOverview({ bloodType, bloodTypeRH, heightFeet, heightInches, weight, medicalCondition, surgeries, allergies });
    console.log("heightFeet:", heightFeet);
  }, [heightFeet]);
  useEffect(() => {
    setOverview({ bloodType, bloodTypeRH, heightFeet, heightInches, weight, medicalCondition, surgeries, allergies });
    console.log("heightInches:", heightInches);
  }, [heightInches]);  
  useEffect(() => {
    setOverview({ bloodType, bloodTypeRH, heightFeet, heightInches, weight, medicalCondition, surgeries, allergies });
    console.log("weight:", weight);
  }, [weight]);

  const monthChange = (datePicker, index) => {
    const tempTime = datePicker;
    setSurgeries([
      ...surgeries.slice(0, index),
      {
        ...surgeries[index],
        date: moment(tempTime).format("MM/DD/YYYY 00:00:00")
      },
      ...surgeries.slice(index + 1, surgeries.length)
    ]);
  };
  const [localState, setLocalState] = useLocalStorage('localState', state);
  window.state = state;
  console.log('State before localStorage:', state);
  useEffect(() => {
    // if (_.isNull(state.patientData)) {
    //   dispatch({
    //     type: "UPDATE_STATE_TO_LOCAL_STORAGE",
    //     payload: localState
    //   });
    //   setTimeout(() => console.log("State after dispatch: ", state), 10000);
    // }
    if (_.isNull(state.patientData)) {
      dispatch({
        type: "UPDATE_STATE_TO_LOCAL_STORAGE",
        payload: state,
      });
      setTimeout(() => console.log("State after dispatch: ", window.state), 10000);
    }    
  }, []);
  //console.log('Outside use: ', localState);
  console.log('Outside use: ', state);
  useEffect(() => {
    // if (_.isObject(state.patientData)) {
    //   setLocalState({ ...state });
    //   console.log('Local State Overview: ', localState);
    // }
    if (_.isObject(state.patientData)) {
      setLocalState({ ...state });
      console.log('Local State Overview: ', window.state);
    }   
  }, []);

  useEffect(() => {
    console.log('State in useEffect: ', localState);
    if (localState.patientData === null) {
      navigate("/404");
    }
  }, []);
  return (
    <div className="medicalOverview__container">
      <Title level={2} className="pro-health-or-addison__title">
        Medical Overview
      </Title>
      <Divider />
      <h3 className="medicalOverview__question">What is your height?</h3>
      <InputGroup>
        <div>
          <Input
            style={{ width: '20%' }}
            placeholder="feet"
            onChange={e => setHeightFeet({ feet: e.target.value })}
          />
          <Input
            style={{ width: '30%' }}
            placeholder="inches"
            onChange={e => setHeightInches({ inches: e.target.value })}
          />
        </div>
      </InputGroup>
      <Divider />
      <h3 className="medicalOverview__question">What is your weight?</h3>
      <InputGroup>
        <Input
          style={{ width: '50%' }}
          placeholder="lbs"
          onChange={e => setWeight({ weight: e.target.value })}
        />
      </InputGroup>
      <Divider />
      <h3 className="medicalOverview__question">What is your blood type?</h3>
      <div>Blood Type</div>
      <Radio.Group id={1} className="medicalOverview__input">
        <Radio value={'A'} onClick={() => viewBloodType(true)} onChange={e => setBloodType({ bloodType: e.target.value })}>
          A
        </Radio>
        <Radio value={'B'} onClick={() => viewBloodType(true)} onChange={e => setBloodType({ bloodType: e.target.value })}>
          B
        </Radio>
        <Radio value={'AB'} onClick={() => viewBloodType(true)} onChange={e => setBloodType({ bloodType: e.target.value })}>
          AB
        </Radio>
        <Radio value={'O'} onClick={() => viewBloodType(true)} onChange={e => setBloodType({ bloodType: e.target.value })}>
          O
        </Radio>
        <Radio value={'Unknown'} onClick={() => viewBloodType(false)} onChange={e => setBloodType({ bloodType: e.target.value })}>
          Unknown
        </Radio>
      </Radio.Group>
      {showBloodType && (
        <div>
          <Divider />
          <Radio.Group id={1} className="medicalOverview__input">
          <div>RH Factor</div>
            <Radio value={'+'} onClick={() => viewBloodType(true)} onChange={e => setBloodTypeRH({ bloodTypeRH: e.target.value })}>+</Radio>
            <Radio value={'-'} onClick={() => viewBloodType(true)} onChange={e => setBloodTypeRH({ bloodTypeRH: e.target.value })}>-</Radio>
            <Radio value={''} onClick={() => viewBloodType(true)} onChange={e => setBloodTypeRH({ bloodTypeRH: e.target.value })}>Unknown</Radio>
          </Radio.Group>
          </div>
      )}
      <Divider />
      <h3 className="medicalOverview__question">Do you have any medical conditions?</h3>
      <Radio.Group id={1} className="medicalOverview__input">
        <Radio value onClick={() => viewMedConditions(true)}>
          Yes
        </Radio>
        <Radio value={false} onClick={() => viewMedConditions(false)}>
          No
        </Radio>
      </Radio.Group>
      <Divider />
      {showMedConditions && (
        <div>
          <p className="medicalOverview__question">Enter any existing Medical Conditions:</p>
          {medicalCondition.map((object, medConditionIndex) => (
            <div key={`item${medConditionIndex}`}>
              <h3>
                <Divider />
                Medical Condition #{medConditionIndex + 1}{' '}
                <Button
                  className="medication-remove-button"
                  type="danger"
                  ghost
                  shape="round"
                  onClick={e => {
                    e.preventDefault();
                    e.persist();
                    setMedicalConditions([
                      ...medicalCondition.filter((value, idx) => idx != medConditionIndex),
                    ]);
                  }}
                >
                  <Icon type="minus" />
                  Remove Medical Condition
                </Button>
              </h3>
              <p className="medicalOverview__question">Condition Name:</p>
              <Select
                className="medicalOverview__radio"
                onChange={medicalConditionCode => {
                  setMedicalConditions([
                    ...medicalCondition.slice(0, medConditionIndex),
                    {
                      ...medicalCondition[medConditionIndex],
                      conditionCode: medicalConditionCode,
                      conditionDescription: searchForCondition(
                        medicalConditionCode,
                        MedicalConditions,
                      ),
                    },
                    ...medicalCondition.slice(medConditionIndex + 1, medicalCondition.length),
                  ]);
                }}
              >
                {medicalConditionArray.map(
                  ({ MedicalConditionCode, MedicalConditionDescription }) => (
                    <Option
                      key={`medicalCondition${MedicalConditionCode}`}
                      value={MedicalConditionCode}
                    >
                      {MedicalConditionDescription}
                    </Option>
                  ),
                )}
              </Select>
              <p className="medicalOverview__question">Additional Comments:</p>
              <div style={{ marginBottom: 16 }} className="input">
                <Input
                  className="medications__medication-inputs"
                  placeholder="Comments"
                  value={medicalCondition[medConditionIndex].comment}
                  onChange={e => {
                    setMedicalConditions([
                      ...medicalCondition.slice(0, medConditionIndex),
                      {
                        ...medicalCondition[medConditionIndex],
                        comment: e.target.value,
                      },
                      ...medicalCondition.slice(medConditionIndex + 1, medicalCondition.length),
                    ]);
                  }}
                />
              </div>
            </div>
          ))}

          <Button
            className="medicalOverview__button"
            type="primary"
            shape="round"
            onClick={() => {
              setMedicalConditions([
                ...medicalCondition,
                { conditionCode: '', conditionDescription: '', comment: '' },
              ]);
            }}
          >
            <Icon type="plus" />
            Add Medical Condition
          </Button>
          <Divider />
        </div>
      )}
      <h3 className="medicalOverview__question">Do you have any past surgeries?</h3>
      <Radio.Group id={1} className="medicalOverview__input">
        <Radio value onClick={() => viewSurgeriesProcedures(true)}>
          Yes
        </Radio>
        <Radio value={false} onClick={() => viewSurgeriesProcedures(false)}>
          No
        </Radio>
      </Radio.Group>
      <Divider />

      {showSurgeriesProcedures && (
        <div>
          <p className="medicalOverview__question">Enter previous surgeries or procedures:</p>
          {surgeries.map((object, surgeryIndex) => (
            <div key={`item${surgeryIndex}`} className="medications-container">
              <h3>
                <Divider />
                Surgery/Procedure #{surgeryIndex + 1}{' '}
                <Button
                  className="medication-remove-button"
                  type="danger"
                  ghost
                  shape="round"
                  onClick={e => {
                    e.preventDefault();
                    e.persist();
                    setSurgeries([...surgeries.filter((value, idx) => idx != surgeryIndex)]);
                  }}
                >
                  <Icon type="minus" />
                  Remove Surgery/Procedure
                </Button>
              </h3>
              <p className="medicalOverview__question">Procedure Name:</p>
              <Input
                className="medications__medication-inputs"
                placeholder="Procedure Name"
                maxLength={70}
                value={surgeries[surgeryIndex].procedure}
                onChange={e => {
                  setSurgeries([
                    ...surgeries.slice(0, surgeryIndex),
                    {
                      ...surgeries[surgeryIndex],
                      procedure: e.target.value
                    },
                    ...surgeries.slice(surgeryIndex + 1, surgeries.length)
                  ]);
                }}
              />
              <p className="medicalOverview__question">Select date:</p>
              <DatePicker
                popupStyle={{ width: "350px" }}
                className="medicalOverview__date"
                onChange={(e, datePicker) => monthChange(datePicker, surgeryIndex)}
                placeholder="Select date"
              />
              <p className="medicalOverview__question">Additional Comments:</p>
              <div style={{ marginBottom: 16 }} className="input">
                <Input
                  className="medications__medication-inputs"
                  placeholder="Comments"
                  value={surgeries[surgeryIndex].comment}
                  onChange={inputComment => {
                    setSurgeries([
                      ...surgeries.slice(0, surgeryIndex),
                      {
                        ...surgeries[surgeryIndex],
                        comment: inputComment.target.value
                      },
                      ...surgeries.slice(surgeryIndex + 1, surgeries.length)
                    ]);
                  }}
                />
              </div>
            </div>
          ))}

          <Button
            className="medicalOverview__button"
            type="primary"
            shape="round"
            onClick={() => {
              setSurgeries([...surgeries, { date: '', procedure: '', comment: '' }]);
            }}
          >
            <Icon type="plus" />
            Add Surgery/Procedure
          </Button>
          <Divider />
        </div>
      )}
      <h3 className="medicalOverview__question">Do you have any allergies?</h3>
      <Radio.Group id={1} className="medicalOverview__input">
        <Radio value onClick={() => viewAllergies(true)}>
          Yes
        </Radio>
        <Radio value={false} onClick={() => viewAllergies(false)}>
          No
        </Radio>
      </Radio.Group>
      <Divider />

      {showAllergies && (
        <div>
          <p className="medicalOverview__question">Enter any existing allergies:</p>
          {allergies.map((object, allergyIndex) => (
            <div key={`item${allergyIndex}`} className="medications-container">
              <h3>
                <Divider />
                Allergy #{allergyIndex + 1}{' '}
                <Button
                  className="medication-remove-button"
                  type="danger"
                  ghost
                  shape="round"
                  onClick={e => {
                    e.preventDefault();
                    e.persist();
                    setAllergies([...allergies.filter((value, idx) => idx != allergyIndex)]);
                  }}
                >
                  <Icon type="minus" />
                  Remove Allergy
                </Button>
              </h3>
              <p className="medicalOverview__question">Allergy Name:</p>
              <Select
                className="medicalOverview__radio"
                onChange={allergyCode => {
                  setAllergies([
                    ...allergies.slice(0, allergyIndex),
                    {
                      ...allergies[allergyIndex],
                      allergyCode,
                      allergyDescription: searchForAllergy(allergyCode, Allergies),
                    },
                    ...allergies.slice(allergyIndex + 1, allergies.length)
                  ]);
                }}
              >
                {allergiesArray.map(({ AllergyCode, AllergyDescription }, AllergyObject) => (
                  <Option key={`medicalCondition${AllergyCode}`} value={AllergyCode}>
                    {AllergyDescription}
                  </Option>
                ))}
              </Select>
              <p className="medicalOverview__question">Additional Comments:</p>
              <div style={{ marginBottom: 16 }} className="input">
                <Input
                  className="medications__medication-inputs"
                  placeholder="Comments"
                  value={allergies[allergyIndex].comment}
                  onChange={inputComment => {
                    setAllergies([
                      ...allergies.slice(0, allergyIndex),
                      {
                        ...allergies[allergyIndex],
                        comment: inputComment.target.value
                      },
                      ...allergies.slice(allergyIndex + 1, allergies.length)
                    ]);
                  }}
                />
              </div>
            </div>
          ))}

          <Button
            className="medicalOverview__button"
            type="primary"
            shape="round"
            onClick={() => {
              setAllergies([
                ...allergies,
                { allergyCode: '', allergyDescription: '', comment: '' },
              ]);
            }}
          >
            <Icon type="plus" />
            Add Allergy
          </Button>
        </div>
      )}
    </div>
  );
};

export default MedicalOverview;
