export default `subscription OnCreateAneltoEvents($Account: String!) {
      onCreateAneltoEvents(Account: $Account) {
        id
        userEventsId
        Account
        BPM
        BPMHigh
        BPMLow
        Battery
        BloodPressure
        BlueId
        DeviceNumber
        Diastolic
        Event
        EventCode
        Glucose
        HeartRate
        Id
        PulseAvg
        PulseHigh
        PulseLow
        PulseOx
        RawData
        Systolic
        Thermometer
        TimeStamp
        Timestamp
        Units
        User
        UserId
        Value
        Vital
        Weight
        owner
        createdAt
      }
    }
    `