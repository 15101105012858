import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import Vitals from '../components/Vitals/Vitals';

const VitalsPage = ({ state = {}, dispatch }) => {
  const [path, setPath] = useState('');
  return (
    <Layout
      state={state}
      previousPath="/test-signal"
      dispatch={dispatch}
      nextPath={path}
    >
      <div className="app__content-box">
        <Vitals state={state} dispatch={dispatch} setPath={setPath} />
      </div>
    </Layout>
  );
};
export default VitalsPage;
