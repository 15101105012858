const getCareProfile = `query GetCareProfile($id: ID!) {
  getCareProfile(id: $id) {
    profileDetails {
      specialConcerns
      bloodType
    }
  }
}
`;
export default getCareProfile;
