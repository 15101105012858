import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import MedicalOverview from '../components/MedicalOverview/MedicalOverview';


// eslint-disable-next-line arrow-body-style
const MedicalOverviewPage = ({ state, dispatch }) => {
  const [overview, setOverview] = useState([]);
  const [path, setPath] = useState('');
  return (
    <Layout
      state={state}
      previousPath="/activation-search"
      dispatch={dispatch}
      nextPath={path}
      introduction
      overview={overview}
    >
      <div className="app__content-box">
        <MedicalOverview state={state} dispatch={dispatch} setOverview={setOverview} setPath={setPath} />
      </div>
    </Layout>
  );
};

export default MedicalOverviewPage;
