import React, { useState, useEffect } from 'react';
import { Typography, Input, Radio, Divider, Button, Select, Icon, Checkbox, Col } from 'antd';
import _ from 'lodash';
import { navigate } from '@reach/router';
import { useLocalStorage } from 'standard-hooks';
import TimePicker from '../TimePicker/TimePicker';

const { Title } = Typography;
const { Option } = Select;

/**
 * @desc Reminders page to able to set med reminders and vital reminders
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 * @prop {Object} state - global state of patient data
 * @prop {function} setReminders - sets reminders array equal to the setReminders array
 */
const Reminders = ({ state, setReminders, dispatch, setPath }) => {
  const [viewMedReminders, setViewMedReminders] = useState({ hidden: true });
  const [viewVitalReminders, setViewVitalReminders] = useState({ hidden: true });
  const [nonPrescribedMeds, setNonPrescribedMeds] = useState({ hidden: true });
  setPath('/rapid-activation');
  const [medReminder, setMedReminder] = useState([
    {
      dosageUnit: 'units',
      time: [''],
      dosageAmount: '',
      name: '',
      route: '',
    },
  ]);
  console.log(medReminder);
  const [vitalReminder, setVitalReminder] = useState([{ vitalType: '', time: [''], day: [''] }]);
  const [overCounter, setOverCounter] = useState([{}]);
  useEffect(() => {
    setReminders({ medReminder, vitalReminder });
  }, [medReminder]);
  useEffect(() => {
    setReminders({ medReminder, vitalReminder });
  }, [vitalReminder]);
  useEffect(() => {
    console.log([overCounter]);
  }, [overCounter]);

  const showMedReminders = () => {
    console.log('showing med inputs');
    setViewMedReminders({ hidden: false });
  };
  const hideMedReminders = () => {
    console.log('hiding med inputs');
    setViewMedReminders({ hidden: true });
  };
  const showVitalReminders = () => {
    console.log('showing vital inputs');
    setViewVitalReminders({ hidden: false });
  };
  const hideVitalReminders = () => {
    console.log('hiding vital inputs');
    setViewVitalReminders({ hidden: true });
  };
  const showNonPrescribedMeds = () => {
    console.log('showing non-prescribed med inputs');
    setNonPrescribedMeds({ hidden: false });
  };
  const hideNonPrescribedMeds = () => {
    console.log('hiding non-prescribed med inputs');
    setNonPrescribedMeds({ hidden: true });
  };
  const SelectAfter = ({ idx }) => (
    <Select
      defaultValue="units"
      style={{ width: 150 }}
      value={medReminder[idx].dosageUnit}
      onChange={value => {
        setMedReminder([
          ...medReminder.slice(0, idx),
          {
            ...medReminder[idx],
            dosageUnit: value,
          },
          ...medReminder.slice(idx + 1, medReminder.length),
        ]);
      }}
    >
      <Option value="units">units</Option>
      <Option value="milligrams">mg</Option>
      <Option value="milliliters">mL</Option>
      <Option value="micrograms">mcg</Option>
      <Option value="puffs">puffs</Option>
      <Option value="drops">drops</Option>
    </Select>
  );
  const [localState, setLocalState] = useLocalStorage('localState', state);
  useEffect(() => {
    if (_.isNull(state.patientData)) {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);
  useEffect(() => {
    if (_.isObject(state.patientData)) {
      console.log('Local State: ', localState);
      setLocalState({ ...state });
    }
  }, []);

  useEffect(() => {
    if (localState.patientData === null) {
      navigate('/404');
    }
  }, []);

  return (
    <div className="introduction__container">
      <Title level={2} className="pro-health-or-addison__title">
        Reminders
      </Title>
      <Divider />
      <h3 className="question">Are you currently taking any medications?</h3>
      <Radio.Group id={1} className="medications__radio-group">
        <Radio value onClick={() => showMedReminders()}>
          Yes
        </Radio>
        <Radio value={false} onClick={() => hideMedReminders()}>
          No
        </Radio>
      </Radio.Group>
      <div hidden={viewMedReminders.hidden} className="medications-container">
        <p>Please enter the following for each medication:</p>
        {medReminder.map((object, medicationsIndex) => (
          <div key={`item${medicationsIndex}`} className="medications-container">
            <Divider />
            <h3>
              Medication #{medicationsIndex + 1}{' '}
              <Button
                className="medication-remove-button"
                type="danger"
                ghost
                shape="round"
                onClick={e => {
                  e.preventDefault();
                  e.persist();
                  setMedReminder([...medReminder.filter((value, idx) => idx != medicationsIndex)]);
                }}
              >
                <Icon type="minus" />
                Remove Medication
              </Button>
            </h3>
            <p className="question">Medication Name:</p>
            <Input
              className="medications__medication-inputs"
              placeholder="Medication Name"
              maxLength={70}
              value={medReminder[medicationsIndex].name}
              onChange={e => {
                setMedReminder([
                  ...medReminder.slice(0, medicationsIndex),
                  {
                    ...medReminder[medicationsIndex],
                    name: e.target.value,
                  },
                  ...medReminder.slice(medicationsIndex + 1, medReminder.length),
                ]);
              }}
            />

            <p className="question">Dosage:</p>
            <div style={{ marginBottom: 16 }} className="input">
              <Input
                className="medications__medication-inputs"
                addonAfter={<SelectAfter idx={medicationsIndex} />}
                placeholder="Amount or Quantity"
                maxLength={5}
                value={medReminder[medicationsIndex].dosageAmount}
                onChange={e => {
                  setMedReminder([
                    ...medReminder.slice(0, medicationsIndex),
                    {
                      ...medReminder[medicationsIndex],
                      dosageAmount: e.target.value,
                    },
                    ...medReminder.slice(medicationsIndex + 1, medReminder.length),
                  ]);
                }}
              />
            </div>
            <p className="question">Medication Route:</p>
            <Select
              defaultValue="Select..."
              style={{ width: 120 }}
              value={medReminder[medicationsIndex].route}
              onChange={value => {
                setMedReminder([
                  ...medReminder.slice(0, medicationsIndex),
                  {
                    ...medReminder[medicationsIndex],
                    route: value,
                  },
                  ...medReminder.slice(medicationsIndex + 1, medReminder.length),
                ]);
              }}
            >
              <Option value="oral">Oral</Option>
              <Option value="topical">Topical</Option>
              <Option value="inhaled">Inhaled</Option>
              <Option value="sublingual">Sublingual</Option>
              <Option value="injected">Injected</Option>
              <Option value="other">Other</Option>
            </Select>

            <p className="question">Daily Reminder Time:</p>
            <div className="medications__time-input">
              {medReminder[medicationsIndex].time.map((object, timeIndex) => (
                <div key={`item${timeIndex}`}>
                  <TimePicker
                    timeIndex={timeIndex}
                    medicationsIndex={medicationsIndex}
                    medications={medReminder}
                    setMedications={setMedReminder}
                  />
                </div>
              ))}
              <Button
                className="medication-left-button"
                type="primary"
                shape="round"
                size="small"
                onClick={() => {
                  setMedReminder([
                    ...Object.assign(medReminder, {
                      [medicationsIndex]: {
                        ...medReminder[medicationsIndex],
                        time: [...medReminder[medicationsIndex].time, ''],
                      },
                    }),
                  ]);
                }}
              >
                <Icon type="plus" style={{ color: '#FEC013' }} />
                Add Reminder Time
              </Button>
            </div>
          </div>
        ))}
        <Button
          className="medication-center-button"
          type="primary"
          shape="round"
          onClick={() => {
            setMedReminder([...medReminder, { dosageUnit: 'units', time: [''] }]);
          }}
        >
          <Icon type="plus" style={{ color: '#FEC013' }} />
          Add Medication
        </Button>
      </div>
      <Divider />
      <h3 className="question">Do you need to have a vital reminder?</h3>
      <Radio.Group id={1} className="medications__radio-group">
        <Radio value onClick={() => showVitalReminders()}>
          Yes
        </Radio>
        <Radio value={false} onClick={() => hideVitalReminders()}>
          No
        </Radio>
      </Radio.Group>
      <div hidden={viewVitalReminders.hidden} className="medications-container">
        <p>Please enter the following for each vital reminder:</p>
        {vitalReminder.map((object, vitalIndex) => (
          <div key={`item${vitalIndex}`} className="medications-container">
            <Divider />
            <h3>
              Vital Reminder #{vitalIndex + 1}{' '}
              <Button
                className="medication-remove-button"
                type="danger"
                ghost
                shape="round"
                onClick={e => {
                  e.preventDefault();
                  e.persist();
                  setVitalReminder([...vitalReminder.filter((value, idx) => idx != vitalIndex)]);
                }}
              >
                <Icon type="minus" />
                Remove Vital Reminder
              </Button>
            </h3>
            <p className="question">Vital Type:</p>
            <Select
              className="medications__vital-type"
              defaultValue="Select..."
              placeholder="Vital Device Type"
              value={vitalReminder[vitalIndex].vitalType}
              onChange={value => {
                setVitalReminder([
                  ...vitalReminder.slice(0, vitalIndex),
                  {
                    ...vitalReminder[vitalIndex],
                    vitalType: value,
                  },
                  ...vitalReminder.slice(vitalIndex + 1, vitalReminder.length),
                ]);
              }}
            >
              <Option value="glucometer">Glucometer</Option>
              <Option value="thermometer">Thermometer</Option>
              <Option value="weightScale">Weight Scale</Option>
              <Option value="pulseOximeter">Pulse Oximeter</Option>
              <Option value="bloodPressureCuff">Blood Pressure Cuff</Option>
            </Select>
            <br />
            <p className="question">Day picker:</p>
            <h4>(select all that apply)</h4>
            <Checkbox.Group
              onChange={value => {
                setVitalReminder([
                  ...Object.assign(vitalReminder, {
                    [vitalIndex]: { ...vitalReminder[vitalIndex], day: value || [''] },
                  }),
                ]);
              }}
            >
              <Col span={80}>
                <Checkbox value="monday">Monday</Checkbox>
                <br />
                <Checkbox value="tuesday">Tuesday</Checkbox>
                <br />
                <Checkbox value="wednesday">Wednesday</Checkbox>
                <br />
                <Checkbox value="thursday">Thursday</Checkbox>
                <br />
                <Checkbox value="friday">Friday</Checkbox>
                <br />
                <Checkbox value="saturday">Saturday</Checkbox>
                <br />
                <Checkbox value="sunday">Sunday</Checkbox>
              </Col>
              <br />
            </Checkbox.Group>
            <p className="question">Daily Reminder Time:</p>
            {vitalReminder[vitalIndex].time.map((object, timeIndex) => (
              <div key={`item${timeIndex}`}>
                <TimePicker
                  className="input"
                  timeIndex={timeIndex}
                  medicationsIndex={vitalIndex}
                  medications={vitalReminder}
                  setMedications={setVitalReminder}
                />
              </div>
            ))}
            <Button
              className="medication-left-button"
              type="primary"
              shape="round"
              size="small"
              onClick={() => {
                setVitalReminder([
                  ...Object.assign(vitalReminder, {
                    [vitalIndex]: {
                      ...vitalReminder[vitalIndex],
                      time: [...vitalReminder[vitalIndex].time, ''],
                    },
                  }),
                ]);
                console.log('Vital index', vitalIndex);
              }}
            >
              <Icon type="plus" style={{ color: '#FEC013' }} />
              Add Time
            </Button>
          </div>
        ))}
        <Button
          className="medication-center-button"
          type="primary"
          shape="round"
          onClick={() => {
            setVitalReminder([...vitalReminder, { vitalType: '', time: [''], day: [''] }]);
          }}
        >
          <Icon type="plus" style={{ color: '#FEC013' }} />
          Add Vital Reminder
        </Button>
      </div>
      <Divider />
      <h3>Non-Prescribed Medications</h3>
      <p className="question">Taking any over the counter medications, vitamins, or supplements?</p>
      <Radio.Group id={1} className="medications__radio-group">
        <Radio value onClick={() => showNonPrescribedMeds()}>
          Yes
        </Radio>
        <Radio value={false} onClick={() => hideNonPrescribedMeds()}>
          No
        </Radio>
      </Radio.Group>
      <div hidden={nonPrescribedMeds.hidden} className="medications-container">
        {overCounter.map((object, overCounterIndex) => (
          <div key={`item${overCounterIndex}`} className="medications-container">
            <Divider />
            <h3>
              Non-Prescribed Medication #{overCounterIndex + 1}
              <Button
                className="medication-remove-button"
                type="danger"
                ghost
                shape="round"
                onClick={e => {
                  e.preventDefault();
                  e.persist();
                  setOverCounter([...overCounter.filter((value, idx) => idx != overCounterIndex)]);
                }}
              >
                <Icon type="minus" />
                Remove Medication
              </Button>
            </h3>
            <p className="question">Medication Name:</p>
            <Input
              className="medications__medication-inputs"
              placeholder="Over the counter name"
              value={overCounter[overCounterIndex].name}
              onChange={e => {
                setOverCounter([
                  ...overCounter.slice(0, overCounterIndex),
                  {
                    ...overCounter[overCounterIndex],
                    name: e.target.value,
                  },
                  ...overCounter.slice(overCounterIndex + 1, overCounter.length),
                ]);
              }}
            />
            <p className="question">Frequency:</p>
            <Select
              defaultValue="Choose One"
              style={{ width: 150 }}
              value={overCounter[overCounterIndex].frequency}
              onChange={value => {
                setOverCounter([
                  ...overCounter.slice(0, overCounterIndex),
                  {
                    ...overCounter[overCounterIndex],
                    frequency: value,
                  },
                  ...overCounter.slice(overCounterIndex + 1, overCounter.length),
                ]);
              }}
            >
              <Option value="daily">Daily</Option>
              <Option value="weekly">Weekly</Option>
              <Option value="monthly">Monthly</Option>
            </Select>
          </div>
        ))}
        <Button
          className="medication-center-button"
          type="primary"
          shape="round"
          onClick={e => {
            setOverCounter([...overCounter, e.target.value]);
          }}
        >
          <Icon type="plus" style={{ color: '#FEC013' }} />
          Add Medication
        </Button>
      </div>
    </div>
  );
};

export default Reminders;
