import React, { useState } from 'react';
import Layout from '../components/Layout/Layout';
import DailyNutrition from '../components/DailyNutrition/DailyNutrition';

const DailyNutritionPage = ({ state = {}, dispatch }) => {
  const [questionsData, setQuestionsData] = useState({});
  return (
    <Layout
      state={state}
      previousPath="/reminders"
      nextPath="/home-wellbeing"
      dispatch={dispatch}
      introduction
      questionsData={questionsData}
    >
      <div className="app__content-box">
        <DailyNutrition state={state} dispatch={dispatch} setQuestionsData={setQuestionsData} />
      </div>
    </Layout>
  );
};

export default DailyNutritionPage;
