import React, {
  useState, useEffect,
} from 'react';
import {
  Typography, Button, Result,
} from 'antd';

import { navigate } from '@reach/router';
import { useLocalStorage } from 'standard-hooks';
import image from './Anelto_ProHealthEmergencyButton.png';

const { Title } = Typography;

/**
* @desc Survey questions in the Introduction section regarding medical conditions, rehabilitation, supplemental oxygen, and mental health
* @author Dakota Marquez <dmarquez@ecg-hq.com>
* @prop {Object} state - global state of patient data
* @prop {function} setPath - defines url path
*/

const TestSignal = ({ state, setPath, dispatch }) => {
  const [activated, setActivated] = useState({ status: 'warning', subTitle: 'No Signal', disabled: false });
  const confirmSignal = () => {
    console.log('successful test confirmed');
    setActivated({ status: 'success', subTitle: 'Test Signal Successful!', disabled: true });
    setPath('/vitals');
  };
  const [localState, setLocalState] = useLocalStorage('localState', state);
  useEffect(() => {
    if (JSON.stringify(state.patientData) !== 'null') setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);

  useEffect(() => {
    if (JSON.stringify(state.patientData) === 'null') {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);
  useEffect(() => {
    if (localState.patientData === null) {
      navigate('/404');
    }
  }, []);
  return (
    <div className="test-signal__container">
      <Title level={2}>
            Activation
      </Title>
      <Result
        status={activated.status}
        title="Test Signal Status..."
        subTitle={activated.subTitle}
        extra={[
          <Title level={4}>
            Press the EMERGENCY button on the Pro Health device
          </Title>,
          <img
            style={
              {
                width: '100%',
                paddingTop: '2%',
                maxWidth: '400px',
              }
            }
            src={image}
            alt="ProHealthDevice"
          />,
          <br />,
          <br />,
          <Button
            type="primary"
            shape="round"
            size="large"
            disabled={activated.disabled}
            onClick={() => confirmSignal()}
          >
            Confirm Successful Signal
          </Button>,
        ]}
      />
    </div>
  );
};

export default TestSignal;
