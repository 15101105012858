
import React from 'react';
import { Result, Button } from 'antd';
import { navigate } from '@reach/router';

/**
 * @desc 404 error when refresh during vitals readings.
 * @author Hector Rodriguez <hrodriguez@ecg-hq.com>
 */
const Help = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '10%',
  }}
  >
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={(
        <Button
          type="primary"
          size="large"
          onClick={() => {
            navigate('/');
            window.location.reload();
          }}
        >
          Return to Home
        </Button>
)}
    />
  </div>
);

export default Help;
