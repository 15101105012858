import React, { useState, useEffect } from 'react';
import {
  Typography, Input, Radio, DatePicker, Divider, Button, Checkbox, Col, Icon,
} from 'antd';
import { navigate } from '@reach/router';
import { useLocalStorage } from 'standard-hooks';

const { Title } = Typography;
const { MonthPicker } = DatePicker;

/**
* @desc Survey questions in the Introduction section regarding medical conditions, rehabilitation, supplemental oxygen, and mental health
* @author Jacob Wright <jwright@ecg-hq.com>
* @prop {Object} state - global state of patient data
* @prop {function} setQuestionsData - creates object of medical history survey answers
* @prop {string} path - URL
*/

const MedicalHistory = ({ setQuestionsData, state, path, dispatch }) => {
  const [behavior, setBehavior] = useState(null);
  const [rehabilitation, setRehabilitation] = useState(null);
  const [rehabilitationStartDate, setRehabilitationStartDate] = useState(null);
  const [supplementalOxygen, setSupplementalOxygen] = useState(null);
  const [behaviorHealth, setBehaviorHealth] = useState([null]);

  useEffect(() => {
    console.log(
      [
        rehabilitation,
        rehabilitationStartDate,
        supplementalOxygen,
        behaviorHealth,
      ],
    );
    setQuestionsData(
      {
        rehabilitation,
        rehabilitationStartDate,
        supplementalOxygen,
        behaviorHealth,
      },
    );
  },
  [
    rehabilitation,
    rehabilitationStartDate,
    supplementalOxygen,
    behaviorHealth,
  ]);

  const [localState, setLocalState] = useLocalStorage('localState', state);
  useEffect(() => {
    if (JSON.stringify(state.introQuestions) !== '{}') setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);

  useEffect(() => {
    if (JSON.stringify(state.introQuestions) === '{}') {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);
  useEffect(() => {
    if (localState.patientData === null) {
      navigate('/404');
    }
  }, []);
  return (
    <div className="introduction__container">
      <Title className="pro-health-or-addison__title" level={2}>
      Medical History
      </Title>
      <Divider />

      <h3 className="question">Are you prescribed in-home rehabilitation?</h3>
      <Radio.Group
        onChange={e => setRehabilitation(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
        {rehabilitation === true
          ? (
            <div>
              <h3 className="question">Since when?</h3>
              <MonthPicker
                onChange={(date, dateString) => setRehabilitationStartDate(dateString)}
                placeholder="Select month"
              />
            </div>
          )
          : null
          }
      </Radio.Group>

      <h3 className="question">Are you prescribed supplemental oxygen?</h3>
      <Radio.Group
        onChange={e => setSupplementalOxygen(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>

      <h3 className="question">Do you have any pre-existing behavioral or mental health conditions?</h3>
      <Radio.Group
        onChange={e => setBehavior(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
        {behavior === true
          ? (
            <div>
              <div>
                <h3>Additional Conditions:</h3>
                {behaviorHealth.map((something, idx) => (
                  <div
                    key={`item${idx}`}
                    className="medical-history-container"
                  >
                    <Input
                      className="input"
                      placeholder={`Behavioral/Mental Health Condition #${idx + 1}`}
                      value={behaviorHealth[idx]}
                      maxLength={70}
                      onChange={(e) => {
                        setBehaviorHealth([
                          ...behaviorHealth.slice(0, idx),
                          e.target.value,
                          ...behaviorHealth.slice(idx + 1, behaviorHealth.length),
                        ]);
                      }}
                    />
                    <Button
                      className="medical-history-side-button"
                      value={idx}
                      type="danger"
                      ghost
                      shape="round"
                      onClick={(e) => {
                        e.persist();
                        setBehaviorHealth(
                          [...behaviorHealth.filter((value, idx) => idx != e.target.value)],
                        );
                      }}
                    >
                      <Icon type="minus" />
                    </Button>
                    <br />
                    <br />
                  </div>
                ))}
                <Button
                  type="primary"
                  shape="round"
                  onClick={(e) => {
                    setBehaviorHealth(
                      [...behaviorHealth, e.target.value],
                    );
                  }}
                >
                  <Icon type="plus" />
                  Add Condition
                </Button>
              </div>
            </div>
          )
          : null
        }
      </Radio.Group>
    </div>
  );
};

export default MedicalHistory;
