import React, { useState, useEffect } from 'react';
import {
  Typography, Radio, Divider, InputNumber,
} from 'antd';
import { useLocalStorage } from 'standard-hooks';
import { navigate } from '@reach/router';

const { Title } = Typography;

/**
 * @desc Gather home wellbeing info.
 * @author Jacob Wright <jwright@ecg-hq.com>
 * @prop {Object}  state - global state of patient data
 * @prop {function} setQuestionsData - creates object of other people and pets information
 */
const HomeWellbeing = ({ setQuestionsData, state, dispatch }) => {
  const [additionalPeople, setAdditionalPeople] = useState(null);
  const [wellbeing, setWellbeing] = useState(null);
  const [pets, setPets] = useState(null);
  const [cats, setCats] = useState(null);
  const [catsCount, setCatsCount] = useState(null);
  const [dogs, setDogs] = useState(null);
  const [dogsCount, setDogsCount] = useState(null);

  useEffect(() => {
    console.log(
      [
        additionalPeople,
        wellbeing,
        pets,
        cats,
        catsCount,
        dogs,
        dogsCount,
      ],
    );
    setQuestionsData(
      {
        additionalPeople,
        wellbeing,
        pets,
        cats,
        catsCount,
        dogs,
        dogsCount,
      },
    );
  },
  [
    additionalPeople,
    wellbeing,
    pets,
    cats,
    catsCount,
    dogs,
    dogsCount,
  ]);
  const [localState, setLocalState] = useLocalStorage('localState', state);
  useEffect(() => {
    if (JSON.stringify(state.introQuestions) !== '{}') setLocalState({ ...state });
    console.log('Local State: ', localState);
  }, []);

  useEffect(() => {
    if (JSON.stringify(state.introQuestions) === '{}') {
      console.log('State before dispatch: ', localState);
      dispatch({
        type: 'UPDATE_STATE_TO_LOCAL_STORAGE',
        payload: localState,
      });
      setTimeout(() => console.log('State after dispatch: ', state), 10000);
    }
  }, []);
  useEffect(() => {
    if (localState.patientData === null) {
      navigate('/404');
    }
  }, []);
  return (
    <div className="introduction__container">
      <Title className="pro-health-or-addison__title" level={2}>
        Home Wellbeing
      </Title>
      <Divider />

      <h3 className="question">Are there any additional people living in the home?</h3>
      <Radio.Group
        onChange={e => setAdditionalPeople(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>

      <h3 className="question">Do you have any concerns about your family, financial, mental, or emotional wellbeing?</h3>
      <Radio.Group
        onChange={e => setWellbeing(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>

      <h3 className="question">Do you have any pets?</h3>
      <Radio.Group
        onChange={e => setPets(e.target.value)}
      >
        <Radio value>Yes</Radio>
        <Radio value={false}>No</Radio>
        {pets === true
          ? (
            <div>
              <h3 className="wellbeing__question">Do you have any cats?</h3>
              <Radio.Group
                onChange={e => setCats(e.target.value)}
              >
                <Radio value>Yes</Radio>
                <Radio value={false}>No</Radio>
                {cats === true
                  ? (
                    <div>
                      <h3 className="wellbeing__question">How many cats?</h3>
                      <InputNumber
                        onChange={e => setCatsCount(e)}
                        maxLength={5}
                        min={0}
                      />
                    </div>
                  )
                  : null
                }
              </Radio.Group>
              <br />
              <h3 className="wellbeing__question">Do you have any dogs?</h3>
              <Radio.Group
                onChange={e => setDogs(e.target.value)}
              >
                <Radio value>Yes</Radio>
                <Radio value={false}>No</Radio>
                {dogs === true
                  ? (
                    <div>
                      <h3 className="question">How many dogs?</h3>
                      <InputNumber
                        onChange={e => setDogsCount(e)}
                        maxLength={5}
                        min={0}
                      />
                    </div>
                  )
                  : null
                }
              </Radio.Group>
            </div>
          )
          : null
        }
      </Radio.Group>
    </div>
  );
};

export default HomeWellbeing;
