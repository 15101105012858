import axios from 'axios';
import _ from 'lodash';

const postAddisonCreateUser = async (state) => {
  const {
    introQuestions, xmitID, patientData: { id },
    patientData: { data: { firstName, lastName } },
  } = state;
  const nonNullQuestions = {};
  /**
   * @desc This _.forEach will go through the entire introQuestions object and filter out null or empty values
   * @param obj $introQuestions - the object filled with addison users question data
   * @return array - Fills the array noNullQuestions with non empty values
   */
  _.forEach(introQuestions, (result, questionName) => {
    if (Array.isArray(result)) {
      let tempArray = [];
      let tempObj = {};
      if (questionName === 'secondaryCare' || questionName === 'overCounter') { // Nested forEach for arrays with objs
        _.forEach(result, (arrayResult) => {
          if (arrayResult !== '' && arrayResult !== null) {
            _.forEach(arrayResult, (secondaryCareResult, secondaryCareResultName) => {
              if (secondaryCareResult !== '' && secondaryCareResult !== null) {
                tempObj[secondaryCareResultName] = secondaryCareResult;
              }
            });
            if (!(_.isEmpty(tempObj))) { // Checks whether any information was saved into the temp Obj
              tempArray.push(tempObj); // Pushes into a temp array
              tempObj = {};
            }
          }
        });
        if (tempArray && tempArray.length > 0) { // Checks if temp Array is filled
          nonNullQuestions[questionName] = tempArray; // Adds the filtered array into our new obj
          tempArray = [];
        }
      } else if (!(_.isEmpty(result))) {
        _.forEach(result, (arrayResult) => {
          if (arrayResult !== null && arrayResult !== '') {
            tempArray.push(arrayResult);
          }
        });
        if (tempArray && tempArray.length > 0) {
          nonNullQuestions[questionName] = tempArray;
          tempArray = [];
        }
      }
    } else { // Checks for null and empty in the reaming objects
      // eslint-disable-next-line no-lonely-if
      if (result !== null && result !== '') {
        nonNullQuestions[questionName] = result;
      }
    }
  });

  const addisonUser = {
    introQuestions: nonNullQuestions,
    firstName,
    lastName,
    xmit: xmitID,
    group: 'ECG',
    username: id,
  };
  console.log('Addison user:', addisonUser);
  const ARN = "arn:aws:states:us-east-1:479226928101:stateMachine:AddisonCareUserCreate";
  const data = await axios.post('https://uimnr4xhq8.execute-api.us-east-1.amazonaws.com/dev', { "input": JSON.stringify(addisonUser), "stateMachineArn": ARN},
    { headers: { 'x-api-key': 'FXkBC29Zaq8lVqbnWWXAOaV1vKh6m1Fc1J7J8Sea' } }).catch(error => error);
  return data;
};

export default postAddisonCreateUser;
