import React from 'react';
import Layout from '../components/Layout/Layout';
import ProHealthorAddison from '../components/ProHealthorAddison/ProHealthorAddison';

const ProHealthorAddisonPage = ({ state = {}, dispatch }) => (
  <Layout
    state={state}
    dispath={dispatch}
  >
    <div className="app__content-box">
      <ProHealthorAddison dispatch={dispatch} />
    </div>
  </Layout>
);

export default ProHealthorAddisonPage;
